<template>
  <div>
    <div>
      <Alert
        :show-alert="!filterValid"
        :display-text="$t('base.filterConfig.invalid')"
        :type="'warning'"
      />
      <span class="body-2">
        {{ $t('base.filterConfig.filter') }}:
      </span>
      <v-fade-transition mode="out-in">
        <span
          :key="filterLabel(langPath, item.props.filter, renderValues)"
          class="font-italic"
        >
          {{ filterLabel(langPath, item.props.filter, renderValues) }}
        </span>
      </v-fade-transition>
      <GeneralConfigFilterComponent
        :filters="APIFilters"
        :filter="item.props.filter"
        :possible-values="possibleValues"
        lang-path="tasks.filter."
        class="mt-3"
      />
    </div>
    <v-divider class="my-3" />
    <div>
      <span class="body-2">
        {{ $t('base.filterConfig.sort') }}:
      </span>
      <span class="font-italic">
        {{ sortLabel(langPath, item.props.sort) }}
      </span>
    </div>
    <div class="d-flex flex-column mt-2">
      <div
        v-for="(sort, index) of sorts"
        :key="index"
      >
        <div
          v-if="index > 0"
          class="my-2"
        >
          ... {{ $t('base.filterConfig.sortThen') }} ...
        </div>
        <div class="d-flex flex-nowrap">
          <v-autocomplete
            v-model="sort.attr"
            outlined
            dense
            hide-details
            :items="sortItems"
            @input="sortChanged()"
          />
          <v-autocomplete
            v-model="sort.val"
            outlined
            dense
            hide-details
            :items="sortItemsValues"
            @input="sortChanged()"
          />
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn
                fab
                small
                color="secondary"
                outlined
                class="mt-1 ml-3"
                @click="removeSort(index)"
                v-on="on"
              >
                <v-icon>$removeSort</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('base.filterConfig.removeSort') }}</span>
          </v-tooltip>
        </div>
      </div>
      <v-btn
        v-if="Object.keys(item.props.sort || {}).length < Object.keys(possibleValues).length"
        outlined
        color="secondary"
        class="mt-2"
        @click="addSort"
      >
        <v-icon class="mr-2">
          $addItem
        </v-icon>
        {{ $t('base.filterConfig.addSort') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
    import Alert from "@/app/components/Alert.component";
    import GeneralConfigFilterComponent from "@/app/components/filterConfig/GeneralConfigFilterPart.component";
    import {APIFilters} from "@/service/APIFilters";
    import {TaskFilterMixin} from "@/app/mixins/TaskFilterMixin";

    export default {
        name: "DashboardTaskListConfig",
        components: {GeneralConfigFilterComponent, Alert},
        mixins: [TaskFilterMixin],
        props: {
            item: {
                type: Object,
                default: () => ({})
            },
            possibleValues: {
                type: Object,
                default: () => ({})
            }
        },
        data: () => ({
            sorts: [],
            langPath: 'tasks.filter.',
            filterLabel: APIFilters.makeHumanReadable,
            sortLabel: APIFilters.makeHumanReadableSort,
            APIFilters: APIFilters
        }),
        computed: {
            sortItems: function () {
                const ret = [];
                for (const attr of Object.keys(this.possibleValues)) {
                    ret.push({
                        text: this.$t('tasks.filter.' + attr + '.name'),
                        value: attr
                    });
                }
                return ret;
            },
            sortItemsValues: function () {
                const ret = [];
                for (const type of ['ASC', 'DESC']) {
                    ret.push({
                        text: this.$t('base.sort.' + type),
                        value: type
                    });
                }
                return ret;
            },
            filterValid: function () {
                return this.validateFilter(this.item.props.filter);
            }
        },
        createdOrActivated: function () {
            this.sorts = Object.entries(this.item.props.sort || {}).map(([attr, val]) => ({
                attr,
                val
            }));
        },
        methods: {
            addSort: function () {
                if (this.item.props.sort === undefined) {
                    this.$set(this.item.props, 'sort', {});
                }
                this.sorts.push({
                    attr: Object.keys(this.possibleValues).filter(key => this.item.props.sort[key] === undefined)[0],
                    val: 'ASC'
                });
                this.sortChanged();
            },
            removeSort: function (index) {
                this.sorts.splice(index, 1);
                this.sortChanged();
            },
            sortChanged: function () {
                const sortObj = {};
                this.sorts.forEach(({attr, val}) => {
                    sortObj[attr] = val;
                });
                /* eslint-disable-next-line vue/no-mutating-props */
                this.item.props.sort = sortObj;
            }
        }
    };
</script>

<style scoped>

</style>
