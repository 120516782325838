<template>
  <div
    v-if="filter !== null"
    class="d-flex flex-row flex-wrap flex-gap-8"
  >
    <AutocompleteWithIcons
      v-show="hardcodedAttribute === null"
      :lang-path="langPath"
      :value="attr"
      :items="items"
      @input="propertyChanged"
    />
    <slot name="operator" />
    <template v-if="Array.isArray(possibleValues[attr].values)">
      <v-autocomplete
        v-model="
          /* eslint-disable-next-line vue/no-mutating-props */
          filter[operator][attr][0]
        "
        outlined
        dense
        hide-details
        :items="possibleValues[attr].values || []"
      />
      <v-autocomplete
        v-model="
          /* eslint-disable-next-line vue/no-mutating-props */
          filter[operator][attr][1]
        "
        outlined
        dense
        hide-details
        :items="possibleValues[attr].values || []"
      />
    </template>
    <template v-else-if="showDatePicker">
      <FormDateTimePicker
        v-model="
          /* eslint-disable-next-line vue/no-mutating-props */
          filter[operator][attr][0]
        "
        single
        show-errors
      />
      <FormDateTimePicker
        v-model="
          /* eslint-disable-next-line vue/no-mutating-props */
          filter[operator][attr][1]
        "
        single
        show-errors
      />
    </template>
    <template v-else>
      <v-text-field
        v-model="
          /* eslint-disable-next-line vue/no-mutating-props */
          filter[operator][attr][0]
        "
        outlined
        dense
        hide-details
        :rules="[formRules.required]"
        :type="htmlType"
        :prepend-inner-icon="attrIcon"
      />
      <v-text-field
        v-model="
          /* eslint-disable-next-line vue/no-mutating-props */
          filter[operator][attr][1]
        "
        outlined
        dense
        hide-details
        :rules="[formRules.required]"
        :type="htmlType"
        :prepend-inner-icon="attrIcon"
      />
    </template>
  </div>
</template>

<script>
    import formRules from "@/utils/formRules";
    import {FilterMixin} from "@/app/mixins/FilterMixin";
    import {APIFilterDataType} from "@/service/APIFilters";
    import FormDateTimePicker from "@/app/components/form/FormDateTimePicker.component";
    import AutocompleteWithIcons from "@/app/components/filterConfig/components/AutocompleteWithIcons.component.vue";
    import {FilterType} from "@jagu/rest-api-filters-client/src/enum/filterType";

    export default {
        name: "FilterConfigTernaryOp",
        components: {AutocompleteWithIcons, FormDateTimePicker},
        mixins: [FilterMixin],
        data: () => ({
            formRules: formRules
        }),
        computed: {
            attr: function () {
                return Object.keys(this.filter[this.operator])[0];
            },
            attrIcon: function () {
                const item = this.items.find(el => el.value === this.attr);
                return item !== undefined ? item.icon : null;
            },
            showDatePicker: function () {
                return this.filters.type === FilterType.API && this.possibleValues[this.attr].type === APIFilterDataType.DATE;
            }
        },
        methods: {
            propertyChanged: function (newValue) {
                const oldValue = this.attr;
                delete this.filter[this.operator][oldValue];
                const possiblePropertyValues = this.possibleValues[newValue].values;
                this.$set(this.filter[this.operator], newValue, [
                    Array.isArray(possiblePropertyValues) ? possiblePropertyValues[0].value : undefined,
                    Array.isArray(possiblePropertyValues) ? possiblePropertyValues[1].value : undefined
                ]);
            }
        }
    };
</script>

<style scoped>

</style>
