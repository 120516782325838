import {TableFilter} from "@/enum/table_filter";
import {taskNames, taskTypes} from "@/enum/task_type";
import i18n from "@/service/lang/i18n";

export const ProductReservationsTable = () => {
    return {
        'parent_task.type': {
            label: 'tasks.type',
            sortable: false,
            filterType: TableFilter.SELECT_MULTIPLE,
            filterItems: Object.values(taskTypes).map(type => ({
                text: i18n.t(taskNames[type]),
                value: type,
            })),
        },
        'parent_task.id': {
            sortable: false,
            label: 'tasks.name',
            filterType: TableFilter.NUMBER

        },
        'id': {
            sortable: false,
            label: 'tasks.productsReservation.name',
            filterType: TableFilter.NUMBER
        },
    };
};