<template>
  <v-dialog
    v-model="model"
    width="600"
  >
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>

      <v-card-text>
        {{ text }}
        <slot />
        <v-col
          v-if="!batchPrint"
          xs="6"
        >
          <v-text-field
            v-model="count"
            prepend-icon="$barcodePrintQuantity"
            type="number"
            :rules="[formRules.required, formRules.positiveInteger]"
          />
        </v-col>
        <v-col
          v-else
          xs="6"
        >
          <v-select
            v-model="justOnePerItem"
            prepend-icon="$barcodePrintQuantity"
            :rules="[formRules.required]"
            :items="batchPrintItems"
          />
        </v-col>
        <v-col
          xs="6"
        >
          <v-select
            v-model="type"
            prepend-icon="$barcodePrintFormat"
            :rules="[formRules.required]"
            :items="getPrintTypeItems()"
          />
        </v-col>
      </v-card-text>

      <v-card-actions>
        <v-btn
          text
          @click="cancel"
        >
          {{ $t('base.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="secondary"
          text
          :disabled="disabled"
          @click="$emit('print-barcode')"
        >
          {{ confirmText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
    import formRules from "@/utils/formRules";
    import {getPrintTypeItems} from "@/enum/print_type";

    export default {
        name: "BarcodePrintDialog",
        props: {
            show: {
                type: Boolean,
                default: false
            },
            barcodeCount: {
                type: Number,
                default: 1
            },
            printType: {
                type: String,
                default: 'pdf'
            },
            title: {
                type: String,
                default: null
            },
            text: {
                type: String,
                default: null
            },
            confirmText: {
                type: String,
                default: null
            },
            disabled: {
                type: Boolean,
                default: false
            },
            batchPrint: {
                type: Boolean,
                default: false
            },
            printJustOnePerItem: {
                type: Boolean,
                default: false
            },
            items: {
                type: Array,
                default: () => []
            },
        },
        data: () => ({
            formRules: formRules,
            getPrintTypeItems: getPrintTypeItems,
            langPath: 'products.instances.barcodes.batchPrint.',
        }),
        computed: {
            model: {
                get: function () {
                    return this.show;
                },
                set: function (newValue) {
                    this.$emit('update:show', newValue);
                }
            },
            count: {
                get: function () {
                    return this.barcodeCount;
                },
                set: function (newValue) {
                    let parsedCount = 0;
                    if (newValue !== undefined && newValue !== null && newValue !== '') {
                        parsedCount = Number.parseInt(newValue, 10);
                    }
                    this.$emit('update:barcode-count', parsedCount);
                }
            },
            type: {
                get: function () {
                    return this.printType;
                },
                set: function (newValue) {
                    this.$emit('update:print-type', newValue);
                }
            },
            justOnePerItem: {
                get: function () {
                    return this.printJustOnePerItem ? 'JUST_ONE' : 'ALL';
                },
                set: function (newValue) {
                    this.$emit('update:print-just-one-per-item', newValue === 'JUST_ONE');
                }
            },
            batchPrintItems: function () {
                const pieces = this.items.reduce((acc, curr) => acc + curr.quantity_to_move, 0);
                return [
                    {
                        text: `${this.$t(`${this.langPath}printAll`)} (${this.$t(`${this.langPath}totally`)} ${pieces})`,
                        value: 'ALL'
                    },
                    {
                        text: `${this.$t(`${this.langPath}printJustOne`)} (${this.$t(`${this.langPath}totally`)} ${this.items.length})`,
                        value: 'JUST_ONE'
                    }
                ];
            }
        },
        methods: {
            cancel: function () {
                this.model = false;
                this.$emit('cancel-print');
            }
        }
    };
</script>

<style scoped>

</style>
