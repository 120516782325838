import {FilterDataTypeIcon, FilterHTMLType} from "@/enum/filters";
import {APIFilterDataType, APIFilterOP} from "@/service/APIFilters";
import {FilterType} from "@jagu/rest-api-filters-client/src/enum/filterType";

const FilterMixin = {
    props: {
        filters: {
            type: Object,
            required: true
        },
        filter: {
            type: Object,
            default: null
        },
        possibleValues: {
            type: Object,
            default: () => ({})
        },
        langPath: {
            type: String,
            default: ''
        },
        hardcodedAttribute: {
            type: String,
            default: null
        },
        allowedOperators: {
            type: Array,
            default: null
        }
    },
    computed: {
        operator: function () {
            return Object.keys(this.filter)[0];
        },
        items: function () {
            return Object.keys(this.possibleValues).filter(key => {
                return this.filters.isFilterOPAllowedForDataType(this.operator, this.possibleValues[key].type);
            }).map(key => ({
                text: this.hardcodedAttribute ? '' : this.$t(this.langPath + key + '.name'),
                value: key,
                icon: this.getIcon(this.possibleValues[key].type)
            }));
        },
        newOperator: function () {
            if (this.filters.type === FilterType.API) {
                return this.getNewOperator(APIFilterOP.EQUALS, [APIFilterOP.AND, APIFilterOP.OR]);
            } else {
                console.warn('Computed property `newOperator` in FilterMixin is not set for ' + this.filters.type + ' implementation');
                return undefined;
            }
        },
        htmlType: function () {
            const type = this.possibleValues[this.attr].type;
            if (Object.keys(FilterHTMLType).includes(type)) {
                return FilterHTMLType[type];
            }
            console.warn('Computed property `htmlType` for "' + type + '" type in FilterMixin is not set for ' + this.filters.type + ' implementation');
            return undefined;
        }
    },
    methods: {
        getDefaultDataTypeValue: function (attr) {
            const value = this.possibleValues[attr];
            if (Array.isArray(value.values)) {
                return value.values[0].value;
            } else {
                switch (value.type) {
                    case APIFilterDataType.NUMBER:
                        return 0;
                    case APIFilterDataType.DATE:
                        return this.$moment().startOf('day').format();
                    case APIFilterDataType.BOOLEAN:
                        return true;
                    case APIFilterDataType.TEXT:
                        return '';
                    default:
                        console.warn('Method `getDefaultDataTypeValue` in FilterMixin does not have case for "' + value.type + '" type from ' + this.filters.type + ' implementation');
                        return undefined;
                }
            }
        },
        getIcon: function (dataType) {
            if (Object.keys(FilterDataTypeIcon).includes(dataType)) {
                return FilterDataTypeIcon[dataType];
            }
            console.warn('Method `getIcon` in FilterMixin does not have value for "' + dataType + '" type from ' + this.filters.type + ' implementation');
            return undefined;
        },
        getNewOperator: function (defaultOperator, groupOperators) {
            if (this.allowedOperators === null) {
                return defaultOperator;
            } else {
                return this.allowedOperators.find(filter => groupOperators.indexOf(filter) === -1);
            }
        }
    }
};

export {FilterMixin};
