import {Env} from "@/service/Environment";
import {Store} from "@/service/store/Store";
import {Sentry} from "@/service/Sentry";
import {flatten, unfold} from "@/utils/object";
import moment from "moment";
import {PrintType} from "@/enum/print_type";
import {Electron} from "@/service/Electron";
import {ReportPrintTypes} from "@/enum/report_type";
import {ISO_8601_DATE_TIME_FORMAT} from "@/utils/datetime";
import {themes} from "@/styles/theme";

/**
 * @param href {string}
 * @param filename {string}
 */
function downloadFile(href, filename) {
    // In APK use system call
    try {
        window.Android.printUrls(JSON.stringify([href]));
    } catch (error) {
        // If not in APK, do a direct download
        const a = window.document.createElement('a');
        // a.href = href.replace('http://', 'https://');
        a.href = href;
        a.download = filename;
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
    Store.commit('snackbar/setVisible', false);
}

function escapeCSVEntry(csvEntry) {
    let escapedEntry = csvEntry + '';
    escapedEntry = escapedEntry.replace(/"/g, '""');
    if (escapedEntry.includes(';')) {
        escapedEntry = '"' + escapedEntry + '"';
    }
    return escapedEntry;
}

/**
 * @param data {Object[]}
 * @param headers {string[]} if none or '[]' provided, first data object keys will be used as headers
 * @param filename {string}
 */
function csv(data, headers = [], filename = 'export.csv') {
    let csv = '';
    if (headers.length === 0) {
        headers = Array.from(Object.entries(data[0]), item => {
            return generateHeader(item[1], item[0], data);
        });
        headers = Object.values(flatten(headers)).filter(item => typeof item === 'string');
    }
    csv += headers.map(escapeCSVEntry).join(';') + '\r\n';
    for (const item of data) {
        const flatItem = flatten(item);
        csv += headers
                .map((header) => {
                    const headerName = header || '';
                    let value = flatItem[header];
                    if (value === null || value === undefined) {
                        value = '';
                    }
                    if (headerName.endsWith('id') || headerName.startsWith('id')) {
                        return value;
                    } else if (moment(value, ISO_8601_DATE_TIME_FORMAT, true).isValid()) {
                        return moment(value).format(ISO_8601_DATE_TIME_FORMAT);
                    } else {
                        return value;
                    }
                })
                .map(escapeCSVEntry)
                .join(';')
            + '\r\n';
    }
    downloadFile('data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv), filename);
}

function xlsx(data, headers = [], filename = 'export.xlsx') {
    const ExcelJS = require('exceljs');
    if (headers.length === 0) {
        headers = Array.from(Object.entries(data[0]), item => {
            return generateHeader(item[1], item[0], data);
        });
        headers = Object.values(flatten(headers)).filter(item => typeof item === 'string');
    }

    // Creating WorkBook and adding Sheet to it
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet('excel');

    // Creating aoa for rows
    const rows = Array.from(data, row => {
        return Array.from(headers, header => {
            const val = unfold(row, header);
            if (moment(val, ISO_8601_DATE_TIME_FORMAT, true).isValid()) {
                return new Date(val);
            } else if (isValidURL(val)) {
                return {
                    text: val,
                    hyperlink: val
                };
            }
            return val === undefined ? null : val;
        });
    });

    xlsxStyleColumns(ws, headers, rows);

    const headerRow = ws.getRow(1);
    headerRow.values = headers;
    xlsxStyleHeader(headerRow);
    xlsxAddFilters(ws, 1, 1, 1, headers.length);

    data.forEach((item, index) => {
        const row = ws.getRow(index + 2);
        row.values = rows[index];
        xlsxStyleRow(row, index);
    });

    wb.xlsx.writeBuffer()
        .then(res => {
            const blob = new Blob([res], {type: "application/vnd.ms-excel"});
            const fileURL = URL.createObjectURL(blob);
            downloadFile(fileURL, filename);
        });
}

function isValidURL(str) {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    return !!urlRegex.test(str);
}

/**
 *
 * @param header {object}
 * @param headerPath { string }
 * @param data { object[] }
 */
function generateHeader(header, headerPath, data) {
    if (header === null) {
        return generateHeader(unfold(data.find(item => {
            const val = unfold(item, headerPath);
            return typeof val === 'object' && val !== null;
        }), headerPath), headerPath, data);
    } else if (Array.isArray(header)) {
        const groupLengths = Array.from(data, item => unfold(item, headerPath))
            .map(item => item === undefined ? [] : item);
        return Array.from(groupLengths.reduce((acc, val) => val.length > acc.length ? val : acc),
            (item, index) => generateHeader(item, headerPath + '[' + index + ']', data));
    } else if (typeof header === 'object' && !Array.isArray(header)) {
        let headerSet = new Set(Object.keys(header)); // for quicker search
        data.forEach(row => {
            const rowObject = unfold(row, headerPath);
            if (rowObject) {
                const rowKeys = Object.keys(rowObject);
                if (rowKeys.length !== header.length || rowKeys.some(rk => !headerSet.has(rk))) {
                    header = {
                        ...header,
                        ...rowObject
                    };
                    headerSet = new Set(Object.keys(header));
                }
            }
        });
        return Array.from(Object.entries(header), item => {
            return generateHeader(item[1], headerPath + '.' + item[0], data);
        });
    } else {
        return headerPath;
    }
}

function countGroupDepth(group) {
    let maxDepth = 0;
    group.columns.filter(item => item.columns).forEach(group => {
        maxDepth = Math.max(maxDepth, countGroupDepth(group));
    });
    return maxDepth + 1;
}

async function formatXlsx(url, template) {
    const ExcelJS = require('exceljs');
    const oldWb = new ExcelJS.Workbook;
    let headerRowPos = countGroupDepth(template.columns[0]) + 1;

    await fetch(url)
        .then(file => file.blob())
        .then(blob => blob.arrayBuffer())
        .then(buffer => oldWb.xlsx.load(buffer));

    const oldWs = oldWb.worksheets[0];
    const headerRowValues = [];
    oldWs.getRows(1, headerRowPos).forEach(row => {
        row.values.forEach((val, index) => {
            if (val) {
                headerRowValues[index] = val;
            }
        });
    });
    const rowsValues = [];
    oldWs.getRows(1, oldWs.rowCount).forEach(row => {
        rowsValues.push(row.values);
    });

    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet('excel');

    rowsValues.forEach((row, index) => {
        const rowData = ws.getRow(index + 1);
        rowData.values = row;
        xlsxStyleRow(rowData, index, headerRowPos);
    });

    ws.insertRow(headerRowPos + 1, headerRowValues);
    headerRowPos++;
    xlsxStyleHeader(ws.getRow(headerRowPos));
    xlsxAddFilters(ws, headerRowPos, 1, headerRowPos, ws.columnCount);

    const headerRow = ws.getRow(headerRowPos);
    xlsxStyleColumns(ws, headerRow.values, rowsValues, false);

    wb.xlsx.writeBuffer()
        .then(res => {
            const blob = new Blob([res], {type: "application/vnd.ms-excel"});
            const fileURL = URL.createObjectURL(blob);
            downloadFile(fileURL, template.domain + '.xlsx');
        });

}

/**
 * Function for adding filter to xlsx file
 @param workSheet { Exceljs.workbook.worksheet } object
 @param fromRow {number}
 @param fromColumn {number}
 @param toRow {number}
 @param toColumn {number}*
 */
function xlsxAddFilters(workSheet, fromRow, fromColumn, toRow, toColumn) {
    workSheet.autoFilter = {
        from: {
            row: fromRow,
            column: fromColumn
        },
        to: {
            row: toRow,
            column: toColumn
        }
    };
}

/**
 * Function for styling rows(adding background color to even rows) in xlsx file
 @param row { Exceljs.workbook.worksheet.row } object
 @param index {number}
 @param headerOffset {number}
 */
function xlsxStyleRow(row, index, headerOffset = 0) {
    if (index % 2 === 0 && index >= headerOffset) {
        row.eachCell({includeEmpty: true}, cell => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: themes.light.tableBackground.slice(1)}, // Even row color
            };
        });
    }
}


/**
 * Function for styling header (adding background color and changing text color) in xlsx file
 @param headerRow { Exceljs.workbook.worksheet.row } object
 */
function xlsxStyleHeader(headerRow) {
    headerRow.eachCell((cell) => {
        cell.font = {
            color: {argb: 'FFFFFF'}, // White text color
            bold: true,                // Make text bold
        };
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: themes.light.secondary.slice(1)},
        };
    });
}

/**
 * Function for styling columns (adding width and data format for dates) in xlsx file
 @param ws { Exceljs.workbook.worksheet } object
 @param headers { Array<string> } object
 @param rowData { Array<Array<string>> } object
 @param setHeaders { boolean } object
 */
function xlsxStyleColumns(ws, headers, rowData, setHeaders = true) {
    ws.columns = headers.map((item, index) => {
        const column = {
            header: setHeaders ? item : undefined,
            key: setHeaders ? item : undefined,
            width: item.length < 20 ? 20 : item.length + 5,
            outlineLevel: 1,
        };
        if (rowData.some(row => moment(row[index], ISO_8601_DATE_TIME_FORMAT, true).isValid())) {
            column.style = {
                numFmt: 'dd/mm/yyyy hh:mm:ss'
            };
        }
        return column;
    });
}

/**
 * @param data {string} binary-streamed PDF
 * @param filename {string}
 */
function pdf(data, filename = 'report.pdf') {
    const file = new Blob([data], {type: 'application/pdf'});
    const fileURL = URL.createObjectURL(file);
    downloadFile(fileURL, filename);
}

/**
 * @param data {string} binary stream
 * @param content_type {string}
 * @param filename {string}
 */
function any(data, content_type, filename = 'downloaded_file') {
    const file = new Blob([data], {type: content_type});
    const fileURL = URL.createObjectURL(file);
    downloadFile(fileURL, filename);
}

/**
 * @param url {string}
 * @param filename {string}
 */
function url(url, filename = 'downloaded_file') {
    downloadFile(url, filename);
}

/**
 * In Electron, print using Electron config, in other environments, download the label.
 * @param url {string}
 * @param filename {string}
 * @param printType {PrintType} TODO drop this once all clients use Electron >=5
 */
function print(url, filename = 'print_file', printType) {

    if (Env.isElectron()) {

        const messageBus = window.require('electron').ipcRenderer;

        messageBus.invoke('public.version.get')
            .then(version => {
                window.console.info(`Detected ElectroWhale version ${version}`);
                if (version >= '5.0.0') {
                    if (version >= '6.0.0') {
                        Sentry.captureWarning(`Unsupported ElectroWhale version ${version} detected, update this domain ASAP!`);
                    }
                    messageBus.invoke('public.print.byMimeType', url)
                        .then(() => {
                            Store.commit('snackbar/set', {text: 'base.print.sent'});
                        })
                        .catch(err => {
                            // TODO print just 'err' once https://github.com/electron/electron/issues/24427 is resolved
                            const split = (err.toString()).split('public.print.byMimeType');
                            Store.commit('snackbar/set', {text: split[1].slice(3) || split[0], timeout: 0});
                        });
                } else {
                    throw new Error(`ElectroWhale version ${version} < 5.0.0 is legacy`);
                }
            })
            .catch(() => { // TODO Drop this once all envs use ElectroWhale >=5
                window.console.info('Legacy ElectroWhale detected');
                if (printType === PrintType.PDF) {
                    messageBus.send('system.printPdf', url);
                    // Fallback for old Electron. Drop this in the future
                    messageBus.send('system.printUrl', url, /* options: */ undefined, /* PDF: */ true);
                    Store.commit('snackbar/set', {text: 'base.print.sent'});
                } else if (printType === PrintType.ZPL) {
                    messageBus.send('system.printLabelZebra', url);
                    Store.commit('snackbar/set', {text: 'base.print.sent'});
                } else if (printType === PrintType.ESCP) {
                    messageBus.send('system.printLabelBrother', url);
                    Store.commit('snackbar/set', {text: 'base.print.sent'});
                } else if (printType === PrintType.ZPLX) {
                    messageBus.send('system.printLabelZebraZplx', url);
                    Store.commit('snackbar/set', {text: 'base.print.sent'});
                } else if (printType === PrintType.ZPLX2) {
                    messageBus.send('system.printLabelZebraZplx2', url);
                    Store.commit('snackbar/set', {text: 'base.print.sent'});
                } else {
                    downloadFile(url, filename);
                }
            });
    } else {
        downloadFile(url, filename);
    }
}

/**
 * @param api {Object}
 * @param taskId {number|string}
 * @param preferredMimeType {ReportType.*}
 * @param filePrefix {string}
 * @returns {Promise<*>}
 */
function report(api, taskId, preferredMimeType, filePrefix = 'report') {
    return new Promise((resolve, reject) => {
        let promise = Promise.resolve(preferredMimeType);
        if (Env.isElectron()) {
            promise = Electron.getConfiguredMimeType(preferredMimeType)
                .then(configuredMimeType => {
                    if (!configuredMimeType) {
                        Store.commit('snackbar/set', {
                            text: 'base.print.noConfigFor',
                            params: [ReportPrintTypes.map(rpt => rpt.mime).join(', ')]
                        });
                        resolve();
                    }
                    return configuredMimeType;
                }).catch(reject);
        }
        promise.then(selectedMimeType => {
            api.report(taskId, selectedMimeType.mime)
                .then(response => {
                    print(
                        response.data.url,
                        `${filePrefix}_${taskId}.${selectedMimeType.suffix}`,
                        selectedMimeType.print
                    );
                    resolve();
                })
                .catch(reject);
        }).catch(reject);
    });
}

export {csv, pdf, any, url, print, report, xlsx, formatXlsx};
