<template>
  <v-menu
    v-model="dialog"
    :close-on-content-click="false"
    offset-y
    nudge-top="25"
  >
    <template #activator="{ on }">
      <v-text-field
        ref="input"
        v-model="inputText"
        class="text-field"
        :append-outer-icon="help !== undefined ? 'help_outline' : ''"
        :clearable="!sticky"
        :dense="single"
        :disabled="readonly"
        :error-messages="errors"
        :hide-details="single && !showErrors"
        :hint="currentHint"
        :label="label"
        :name="name"
        :suffix="required === true ? '*' : ''"
        :rules="(inFocus ? [] : [inputValid]).concat(rules === undefined ? [] : rules)
          .concat(required !== true ? [] : [rulesImpl.required])"
        :outlined="single"
        :prepend-icon="single ? null : (prependIcon || '$datePick')"
        :prepend-inner-icon="single ? prependInnerIcon : null"
        persistent-hint
        v-on="on"
        @input="onInput"
        @focusin="inFocus = true"
        @focusout="inFocus = false"
        @click:append-outer.stop="$emit('clickHelp')"
        @click:clear="onClickClear"
        @mouseup.left="isDragging = false"
        @mousedown.left="onMouseDown"
        @mousemove="onMouseMove"
        @keydown.enter="onConfirm"
      >
        <template #append-outer>
          <slot name="append-outer" />
        </template>
      </v-text-field>
    </template>
    <v-sheet
      :width="width + 'px'"
      class="d-flex flex-column"
    >
      <v-slide-group
        ref="slideGroup"
        v-model="activeSelectIndex"
        :show-arrows="showArrows"
        center-active
        class="px-1 py-2 slide-group"
      >
        <v-slide-item
          v-for="(item, index) in datesSelect"
          :key="item"
          v-slot="{ toggle }"
        >
          <v-btn
            rounded
            small
            color="primary"
            elevation="0"
            :outlined="activeSelectIndex !== index"
            :class="{ 'button-border': activeSelectIndex === index }"
            @click="setTime(item, index, toggle)"
          >
            {{ $t('form.' + item) }}
          </v-btn>
        </v-slide-item>
      </v-slide-group>
      <div
        class="d-flex flex-row flex-wrap px-1"
        style="gap: 4px"
      >
        <v-date-picker
          v-model="localDateValue"
          :first-day-of-week="dayOfWeek.MONDAY"
          show-current
          class="date-picker"
          :active-picker.sync="activeDatePicker"
          :events="eventDates"
          @change="onPickerChange"
        />
        <v-time-picker
          v-if="timePicker"
          v-model="localTimeValue"
          format="24hr"
          use-seconds
          :active-picker.sync="activeTimePicker"
          @click:hour="onPickerChange"
          @click:minute="onPickerChange"
          @click:second="onPickerChange"
        />
      </div>
      <v-btn
        class="ma-1 flex-grow-1"
        color="primary"
        elevation="0"
        :disabled="!isValid"
        @click="onConfirm"
      >
        <v-icon>
          $check
        </v-icon>
      </v-btn>
    </v-sheet>
  </v-menu>
</template>

<script>
    import rulesImpl from "@/utils/formRules";
    import {dates, DayOfWeek} from "@/enum/dates";
    import {ResizeSensor} from "css-element-queries";
    import {inputMethod} from "@/enum/date_time_picker";
    import {DATE_DISPLAY_FORMAT, DATETIME_DISPLAY_FORMAT, SHORT_DATE_DISPLAY_FORMAT, isoFromDateTime} from "@/utils/datetime";

    export default {
        name: "FormDateTimePicker",
        props: {
            errors: {
                type: Array,
                default: () => []
            },
            help: {
                type: String,
                default: undefined
            },
            prependIcon: {
                type: String,
                default: 'today'
            },
            label: {
                type: String,
                default: ''
            },
            name: {
                type: String,
                default: 'datetimepicker'
            },
            single: {
                type: Boolean,
                default: false
            },
            readonly: {
                type: Boolean,
                default: false
            },
            required: {
                type: Boolean,
                default: false
            },
            rules: {
                type: Array,
                default: () => []
            },
            sticky: {
                type: Boolean,
                default: false
            },
            timePicker: {
                type: Boolean,
                default: true
            },
            value: {
                type: String,
                default: ''
            },
            prependInnerIcon: {
                type: String,
                default: ''
            },
            showErrors: {
                type: Boolean,
                default: false
            },
            events: {
                type: Array,
                default: () => []
            },
            endOfDayTime: {
                type: Boolean,
                defaul: false
            }
        },
        data: () => ({
            rulesImpl: rulesImpl,
            dayOfWeek: DayOfWeek,
            dialog: false,
            localDateValue: null,
            localTimeValue: null,
            inputText: '',
            inFocus: false,
            probableDateFormats: [],
            wrap: true,
            resizeSensor: null,
            activeSelectIndex: undefined,
            activeDatePicker: 'DATE',
            activeTimePicker: 'HOUR',
            inputMethod: inputMethod.INITIAL,
            showHint: false,
            mouseDownTarget: null,
            isDragging: false,
            showArrows: true,
            widths: {
                small: 298,
                large: 592
            },
            activeSelectToggleFunction: null
        }),
        computed: {
            dateFormat() {
                if (this.timePicker) {
                    return DATETIME_DISPLAY_FORMAT;
                }
                return DATE_DISPLAY_FORMAT;
            },
            datesSelect() {
                if (!this.timePicker) {
                    return Object.values(dates).filter(date => date !== dates.NOW);
                }
                return Object.values(dates);
            },
            localValue() {
                return isoFromDateTime(this.localDateValue, this.localTimeValue);
            },
            currentParse() {
                if (this.inputMethod === inputMethod.INITIAL && this.value !== null && this.value !== '') {
                    return this.$moment(this.value);
                } else if (this.inputMethod !== inputMethod.CLEARED && this.inputText !== '') {
                    return this.$moment(this.inputText.trim(), this.probableDateFormats);
                }
                return undefined;
            },
            currentHint() {
                if (this.showHint && this.inputMethod !== inputMethod.CLEARED) {
                    if (this.currentParse?._isValid) {
                        return this.currentParse.format(this.dateFormat);
                    } else if (this.inputMethod !== inputMethod.INITIAL) {
                        return this.$t('form.invalidDate');
                    }
                }
                return '';
            },
            eventDates() {
                // in events prop we can have dates or dateTimes (or mix) but vuetify date picker only accepts dates as events
                return this.events.map(event => this.$moment(event).format(this.$moment.HTML5_FMT.DATE));
            },
            isEditing() {
                return this.dialog || this.inFocus;
            },
            isValid() {
                switch (this.inputMethod) {
                case inputMethod.INITIAL:
                    return this.value !== null && this.value !== '';
                case inputMethod.PICKER:
                    return this.localDateValue !== null;
                case inputMethod.SELECT:
                    return true;
                case inputMethod.MANUAL:
                    return !!this.currentParse?._isValid;
                default:
                    return false;
                }
            },
            width() {
                return this.wrap ? this.widths.small : this.widths.large;
            }
        },
        watch: {
            value: {
                handler(newValue) {
                    if (newValue) {
                        this.populatePickers(newValue);
                        if (this.inputMethod !== inputMethod.MANUAL) {
                            if (!this.timePicker && this.isEditing) {
                                this.inputText = this.$moment(newValue).format(SHORT_DATE_DISPLAY_FORMAT); // edit mode if time picker prop is false
                            } else {
                                this.inputText = this.$moment(newValue).format(this.dateFormat);
                            }
                        }
                    } else {
                        this.resetPickers();
                    }
                },
                immediate: true
            },
            localValue: function () {
                if (this.inputMethod === inputMethod.PICKER) {
                    this.emit();
                }
            },
            dialog: function (newValue) {
                // support for enter (confirm if valid) and escape (close the dialog) keys
                if (newValue) {
                    document.addEventListener('keydown', this.onKeydown);
                } else {
                    document.removeEventListener('keydown', this.onKeydown);
                }

                // resize sensor if both pickers are enabled
                if (this.timePicker) {
                    if (newValue) {
                        this.resize(); // call resize to reset the initial value on opening
                        this.checkSlideArrows();
                        this.resizeSensor = new ResizeSensor(document.body, this.resize);
                    } else if (this.resizeSensor !== null) {
                        this.resizeSensor.detach();
                        this.resizeSensor = null;
                    }
                }

                this.showHint = this.inFocus && !newValue;
            },
            inFocus: function (newValue) {
                if (!newValue) {
                    this.showHint = false;
                    this.isDragging = false;
                } else if (this.mouseDownTarget !== this.$refs.input.$refs['input']) {
                    // user clicked on the outer part of the input - the dialog does not open but user can write (so we show hint)
                    this.showHint = true;
                }

                if (newValue && !this.timePicker && this.currentParse?._isValid) {
                    // edit mode if time picker prop is false (the text is formatted as DD. MM. YYYY for simpler editing)
                    this.inputText = this.value === null ? '' : this.$moment(this.value).format(SHORT_DATE_DISPLAY_FORMAT);
                }

                setTimeout(() => {
                    // check if the input is in focus but the dialog is not open after 100ms
                    // - user tabbed into the input or clicked on the input and is holding the mouse button without moving
                    if (this.inFocus && !this.dialog) {
                        this.showHint = true;
                    }
                }, 100);
            },
            inputText: function () {
                if (this.inputMethod === inputMethod.MANUAL) {
                    if (this.currentParse?._isValid) {
                        const formatted = this.currentParse.format();
                        this.populatePickers(formatted);
                        const unusedTokensCount = this.currentParse._pf.unusedTokens.length;
                        // auto emit if the date is complete (no unused tokens) or if the time picker is disabled and only 3 tokens are unused
                        if (unusedTokensCount === 0 || (!this.timePicker && unusedTokensCount === 3)) {
                            this.emit({ date: formatted });
                        }
                    } else {
                        // if the manual input is not valid, emit null
                        this.emit({ date: null });
                    }
                }

                if (this.inputText === null) {
                    // vuetify sets the input to null when clear button is clicked
                    this.inputText = '';
                }
            },
            inputMethod: function (newValue, oldValue) {
                switch (newValue) {
                case inputMethod.CLEARED:
                    this.resetSelect();
                    this.resetPickers();
                    this.emit({ date: null });
                    break;
                case inputMethod.PICKER:
                    this.resetSelect();
                    if (this.localValue && (oldValue === inputMethod.MANUAL || oldValue === inputMethod.SELECT)) {
                        this.emit();
                    }
                    break;
                case inputMethod.MANUAL:
                    this.resetSelect();
                    break;
                case inputMethod.SELECT:
                    this.activeDatePicker = 'DATE';
                    this.activeTimePicker = 'HOUR';
                }
            },
            isEditing: function (newValue) {
                if (!newValue && this.currentParse?._isValid) {
                    this.inputText = this.$moment(this.currentParse.format()).format(this.dateFormat);
                    this.emit({ date: this.currentParse.format() });
                }
            },
            wrap: function () {
                this.checkSlideArrows();
                if (this.$refs.slideGroup) {
                    this.$refs.slideGroup.scrollOffset = 0;
                }
            }
        },
        createdOrActivated() {
            this.probableDateFormats = this.generateDateFormatsCombinations();
        },
        beforeDestroy() {
            if (this.resizeSensor !== null) {
                this.resizeSensor.detach();
            }
        },
        methods: {
            emit: function ({event = 'input', date = this.localValue} = {}) {
                if (date !== this.value) {
                    if (this.endOfDayTime && !this.timePicker && date !== null) {
                        const endOfDayTimeDate = this.$moment(date).clone().set({hour: 23, minute: 59, second: 59});
                        this.$emit(event, endOfDayTimeDate.format());
                    } else {
                        this.$emit(event, date);
                    }
                }
            },
            generateDateFormatsCombinations: function () {
                const dates = ['DD.MM.YYYY', 'DD/MM/YYYY', 'DD-MM-YYYY'];
                const separator = ['/', '-', ','];
                const times = ['hh:mm:ss', 'hh.mm.ss', 'hh-mm-ss'];
                const allFormats = [];
                dates.forEach(date => separator.forEach(separator => times.forEach(time => allFormats.push(date + separator + time))));
                return allFormats;
            },
            setTime: function (time, index, toggle) {
                if (this.activeSelectIndex !== index) {
                    this.activeSelectIndex = index;
                    this.activeSelectToggleFunction = toggle;
                    toggle();
                    this.inputMethod = inputMethod.SELECT;
                    let newTime = '';
                    switch (time) {
                    case dates.NOW:
                        newTime = this.$moment();
                        break;
                    case dates.TODAY:
                        newTime = this.$moment().startOf('day');
                        break;
                    case dates.YESTERDAY :
                        newTime = this.$moment().subtract(1, 'days').startOf('day');
                        break;
                    case dates.START_OF_MONTH :
                        newTime = this.$moment().startOf('month');
                        break;
                    case dates.END_OF_LAST_MONTH :
                        newTime = this.$moment().subtract(1, 'months').endOf('month');
                        break;
                    }
                    this.emit({ date: this.$moment(newTime).format() });
                }
            },
            resize: function () {
                // 7px for the scroll bar
                // 0.8 for the 80% of the window (max width of the menu component)
                // 592px is the width of pickers side by side (including gap and padding)
                this.wrap = ((window.innerWidth - 7) * 0.8) < this.widths.large;
            },
            checkSlideArrows: function () {
                if ((this.$refs.slideGroup?.widths?.content || 0) !== 0) {
                    this.showArrows = this.$refs.slideGroup.widths.content > this.width;
                } else {
                    this.showArrows = true;
                }
            },
            resetSelect: function () {
                this.activeSelectIndex = undefined;
                if (this.activeSelectToggleFunction !== null) {
                    this.activeSelectToggleFunction();
                    this.activeSelectToggleFunction = null;
                }
            },
            resetPickers: function () {
                this.localDateValue = null;
                this.localTimeValue = null;
                this.activeDatePicker = 'DATE';
                this.activeTimePicker = 'HOUR';
            },
            populatePickers: function (value) {
                // parseZone to fix a loop when timezone is not local
                const [date, time] = this.$moment.parseZone(value).format(this.$moment.HTML5_FMT.DATE + ' ' + this.$moment.HTML5_FMT.TIME_SECONDS).split(' ');
                if (this.localDateValue !== date) {
                    this.localDateValue = date.replace(/^0{0,3}/, ''); // vuetify date picker does not accept leading zeros in the year
                }
                if (this.localTimeValue !== time) {
                    this.localTimeValue = time;
                }
            },
            onConfirm: function () {
                if (this.isValid) {
                    this.emit();
                    this.emit({ event: 'confirm' });
                    document.activeElement.blur();
                    this.dialog = false;
                }
            },
            onClickClear: function () {
                this.inputMethod = inputMethod.CLEARED;
            },
            onInput: function () {
                if (this.inputText === null || this.inputText === '') {
                    this.inputMethod = inputMethod.CLEARED;
                } else {
                    this.inputMethod = inputMethod.MANUAL;
                }
            },
            onPickerChange: function () {
                this.inputMethod = inputMethod.PICKER;
                this.$refs.input.blur(); // edge case - input loses focus when date picker changes but not when time picker changes
            },
            onMouseDown: function (event) {
                this.mouseDownTarget = event.target;
                if (event.target === this.$refs.input.$refs['input']) {
                    this.isDragging = true;
                }
            },
            onMouseMove: function () {
                if (this.isDragging && !this.dialog) {
                    this.showHint = true;
                }
            },
            onKeydown: function (event) {
                if (event.key === 'Enter') {
                    this.onConfirm();
                } else if (event.key === 'Escape') {
                    this.dialog = false;
                    this.$nextTick(() => {
                        // edge case - input is focused when date picker changes and escape is pressed afterward
                        document.activeElement.blur();
                    });
                }
            },
            inputValid: function () {
                if (this.inputMethod === inputMethod.MANUAL && !this.currentParse?._isValid) {
                    return this.$t('form.invalidDate');
                }
                return true;
            }
        }
    };
</script>

<style scoped lang="sass">
::v-deep .slide-group .v-slide-group__content
  gap: 6px

::v-deep .slide-group .v-slide-group__prev, ::v-deep .slide-group .v-slide-group__next
  min-width: 32px
  flex: 0 1 32px

::v-deep .date-picker .v-picker__title
  padding-bottom: 30px !important

.button-border
  border-width: 1px !important
  border-style: solid !important

::v-deep .date-picker .v-picker__body .v-date-picker-table .v-date-picker-table__events
  bottom: 0 !important
</style>

<style lang="sass">
// fix for the hint color (vuetify sometimes sets it to primary) - needs to be unscoped
.text-field .v-input__control .v-messages:not(.error--text)
  color: rgba(0,0,0,.6) !important
</style>
