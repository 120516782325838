import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCombobox } from 'vuetify/lib/components/VCombobox';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.filter !== null)?_c('div',{staticClass:"d-flex flex-row flex-wrap flex-gap-8"},[_c('AutocompleteWithIcons',{directives:[{name:"show",rawName:"v-show",value:(_vm.hardcodedAttribute === null),expression:"hardcodedAttribute === null"}],attrs:{"lang-path":_vm.langPath,"value":_vm.attr,"items":_vm.items},on:{"input":_vm.propertyChanged}}),_vm._t("operator"),(Array.isArray(_vm.possibleValues[_vm.attr].values))?_c(VAutocomplete,{attrs:{"outlined":"","dense":"","multiple":"","chips":"","small-chips":"","hide-details":"","items":_vm.possibleValues[_vm.attr].values},model:{value:(
      /* eslint-disable-next-line vue/no-mutating-props */
      _vm.filter[_vm.operator][_vm.attr]
    ),callback:function ($$v) {_vm.$set(/* eslint-disable-next-line vue/no-mutating-props */
      _vm.filter[_vm.operator], _vm.attr, $$v)},expression:"\n      /* eslint-disable-next-line vue/no-mutating-props */\n      filter[operator][attr]\n    "}}):(_vm.showError)?_c(VAlert,{staticClass:"ml-1",attrs:{"type":"error","text":"","dense":""}},[_vm._v(" "+_vm._s(_vm.$t('homepage.config.arrayDateConflict'))+" ")]):_c(VCombobox,{attrs:{"outlined":"","dense":"","multiple":"","chips":"","small-chips":"","deletable-chips":"","clearable":"","hide-details":"","append-icon":"","label":_vm.$t('base.filterConfig.fill'),"type":_vm.htmlType,"prepend-inner-icon":_vm.attrIcon},model:{value:(
      /* eslint-disable-next-line vue/no-mutating-props */
      _vm.filter[_vm.operator][_vm.attr]
    ),callback:function ($$v) {_vm.$set(/* eslint-disable-next-line vue/no-mutating-props */
      _vm.filter[_vm.operator], _vm.attr, $$v)},expression:"\n      /* eslint-disable-next-line vue/no-mutating-props */\n      filter[operator][attr]\n    "}})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }