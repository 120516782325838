<template>
  <div>
    <component
      :is="configComponent"
      :filters="filters"
      :filter="filter"
      :possible-values="possibleValues"
      :lang-path="langPath"
      :hardcoded-attribute="hardcodedAttribute"
      :allowed-operators="allowedOperators"
    >
      <template #operator>
        <v-autocomplete
          ref="autocomplete"
          outlined
          dense
          hide-details
          :label="$t('base.filterConfig.condition')"
          :value="operator"
          :items="operators"
          @input="operatorChanged"
          @blur="reSelectValue"
        />
      </template>
    </component>
  </div>
</template>

<script>
    import FilterConfigUnaryOp from "@/app/components/filterConfig/FilterConfigUnaryOp.component";
    import FilterConfigBinaryOp from "@/app/components/filterConfig/FilterConfigBinaryOp.component";
    import FilterConfigTernaryOp from "@/app/components/filterConfig/FilterConfigTernaryOp.component";
    import FilterConfigArrayOp from "@/app/components/filterConfig/FilterConfigArrayOp.component";
    import {APIFilterOP, APIFilterDataTypeAllowedOperations} from "@/service/APIFilters";
    import {FilterMixin} from "@/app/mixins/FilterMixin";
    import {operatorType} from "@jagu/rest-api-filters-client/src/abstractFilters";
    import {FilterType} from "@jagu/rest-api-filters-client/src/enum/filterType";
    import {FilterConfigComponent} from "@/enum/filters";

    export default {
        name: "GeneralConfigFilterPart",
        components: {
            // dynamic components have to be manually imported
            FilterConfigUnaryOp, FilterConfigBinaryOp, FilterConfigTernaryOp, FilterConfigArrayOp,
            // recursive component has to be imported this way
            FilterConfigGroupOp: () => import("@/app/components/filterConfig/FilterConfigGroupOp.component")
        },
        mixins: [FilterMixin],
        computed: {
            filterGroup: function () {
                return this.filters.getType(this.operator);
            },
            operators: function () {
                const possibleDataTypes = [...new Set(Object.values(this.possibleValues).map(val => val.type))];
                if (this.filters.type === FilterType.API) {
                    const allowedForDataTypes = new Set(possibleDataTypes.map(dataType => APIFilterDataTypeAllowedOperations[dataType]).flat());
                    const allowed = Object.values(APIFilterOP)
                        .filter(operator => {
                            if (operator === APIFilterOP.FULL_TEXT) {
                                return false;
                            }
                            if (this.allowedOperators !== null) {
                                return this.allowedOperators.indexOf(operator) !== -1;
                            }
                            return allowedForDataTypes.has(operator);
                        });
                    return allowed
                        .filter(operator => {
                            if (this.filterGroup.type === operatorType.groupOperators) {
                                return this.filters.getType(operator).type === operatorType.groupOperators;
                            } else {
                                return this.filters.getType(operator).type !== operatorType.groupOperators;
                            }
                        })
                        .map(operator => ({
                            text: this.$t('base.filter.' + operator, ['...', '...', '...']),
                            value: operator
                        }));
                } else {
                    console.warn('Computed property `operators` in GeneralConfigFilterPart is not set for ' + this.filters.type + ' implementation');
                    return [];
                }
            },
            configComponent: function () {
                return FilterConfigComponent[this.filterGroup.type];
            }
        },
        methods: {
            filterGroupValue: function (attr) {
                const defaultValue = this.getDefaultDataTypeValue(attr);
                const defaultValue2 = this.getDefaultDataTypeValue(attr);
                return {
                    group: [{
                        [this.newOperator]: {[attr]: defaultValue}
                    }],
                    unary: attr,
                    binary: {[attr]: defaultValue},
                    ternary: {[attr]: [defaultValue, defaultValue2]},
                    array: {[attr]: []},
                };
            },
            operatorChanged: function (newOperator) {
                if (newOperator !== null && newOperator !== this.operator) {
                    const possibleKeys = Object.keys(this.possibleValues).filter(key => {
                        return this.filters.isFilterOPAllowedForDataType(newOperator, this.possibleValues[key].type);
                    });
                    const curFilter = this.filter[this.operator];
                    const curKey = typeof curFilter === 'object' ? Object.keys(this.filter[this.operator])[0] : curFilter;
                    const curOperator = this.operator;
                    const curType = this.filters.getType(curOperator);
                    const newKey = possibleKeys.includes(curKey) ? curKey : possibleKeys[0];
                    const newType = this.filters.getType(newOperator);

                    if (newType.type === operatorType.groupOperators) {
                        // group changed
                        this.$set(this.filter, newOperator, this.filter[curOperator]);
                    } else {
                        // operator changed
                        this.$set(this.filter, newOperator, this.filterGroupValue(newKey)[newType.type]);

                        if (curKey === newKey) {
                            // attribute did not change
                            const curValue = this.filter[curOperator][curKey];
                            const defaultValue = this.getDefaultDataTypeValue(newKey);
                            if (this.filters.isValueConvertable(curType, newType)
                                && curValue !== null && curValue !== undefined && curValue.length !== 0) {
                                const convertedValue = this.filters.convertValue(curType, newType, curValue, defaultValue);
                                this.$set(this.filter[newOperator], newKey, convertedValue);
                            }
                        } else {
                            // attribute changed because of operator incompatibility - inform user about attribute change with snack
                            this.advancedSnack({
                                text: 'base.filterConfig.attributeChanged',
                                params: [
                                    '"' + this.$t(this.langPath + curKey + '.name') + '"',
                                    '"' + this.$t(this.langPath + newKey + '.name') + '"'
                                ]
                            });
                        }
                    }

                    delete this.filter[curOperator];
                }
            },
            reSelectValue: function () {
                this.$refs.autocomplete.selectItem(this.operator);
            }
        }
    };
</script>

<style scoped>

</style>
