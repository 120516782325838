<template>
  <div
    v-if="filter !== null"
    class="d-flex flex-row flex-wrap flex-gap-8"
  >
    <AutocompleteWithIcons
      v-show="hardcodedAttribute === null"
      :lang-path="langPath"
      :value="attr"
      :items="items"
      @input="propertyChanged"
    />
    <slot name="operator" />
    <v-autocomplete
      v-if="Array.isArray(possibleValues[attr].values)"
      v-model="
        /* eslint-disable-next-line vue/no-mutating-props */
        filter[operator][attr]
      "
      outlined
      dense
      multiple
      chips
      small-chips
      hide-details
      :items="possibleValues[attr].values"
    />
    <v-alert
      v-else-if="showError"
      type="error"
      text
      dense
      class="ml-1"
    >
      {{ $t('homepage.config.arrayDateConflict') }}
    </v-alert>
    <v-combobox
      v-else
      v-model="
        /* eslint-disable-next-line vue/no-mutating-props */
        filter[operator][attr]
      "
      outlined
      dense
      multiple
      chips
      small-chips
      deletable-chips
      clearable
      hide-details
      append-icon=""
      :label="$t('base.filterConfig.fill')"
      :type="htmlType"
      :prepend-inner-icon="attrIcon"
    />
  </div>
</template>

<script>
    import {FilterMixin} from "@/app/mixins/FilterMixin";
    import {APIFilterDataType} from "@/service/APIFilters";
    import AutocompleteWithIcons from "@/app/components/filterConfig/components/AutocompleteWithIcons.component.vue";
    import {FilterType} from "@jagu/rest-api-filters-client/src/enum/filterType";

    export default {
        name: "FilterConfigArrayOp",
        components: {AutocompleteWithIcons},
        mixins: [FilterMixin],
        computed: {
            attr: function () {
                return Object.keys(this.filter[this.operator])[0];
            },
            attrIcon: function () {
                const item = this.items.find(el => el.value === this.attr);
                return item !== undefined ? item.icon : null;
            },
            showError: function () {
                return this.filters.type === FilterType.API && this.possibleValues[this.attr].type === APIFilterDataType.DATE;
            }
        },
        methods: {
            propertyChanged: function (newValue) {
                const oldValue = this.attr;
                delete this.filter[this.operator][oldValue];
                this.$set(this.filter[this.operator], newValue, []);
            }
        }
    };
</script>

<style scoped>

</style>
