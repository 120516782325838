import {config} from "@/config/config";
import Vue from "vue";
import * as SentryService from "@sentry/vue";
import {Integrations} from "@sentry/tracing";
import {Env} from "@/service/Environment";
import {Store} from "@/service/store/Store";

const Sentry = {

    enableInDev: false, // Just for quick testing

    init() {
        if (Env.isProduction() || this.enableInDev) {
            SentryService.init({
                app: Vue,
                dsn: 'https://705cf6e7ac97413397ecee173ef00c1c@sentry.jagu.cz/11',
                environment: config.DOMAIN_LABEL,
                release: config.APP_VERSION,
                logErrors: true, // Also dump errors in the browser console,

                // Tracing
                integrations: [new Integrations.BrowserTracing()],
                tracesSampleRate: 1.0,
                tracingOptions: {
                    trackComponents: true,
                },
            });
        }
    },

    registerUser() {
        SentryService.configureScope(scope => {
            scope.setUser({
                "id": Store.getters['oauth/getUserId'],
                "fullname": Store.getters['oauth/getUserFullName']
            });
        });
    },

    captureMessage(message, tags) {
        if ((Env.isProduction() || this.enableInDev)) {
            SentryService.captureMessage(message, {
                context: 'info',
                tags: {
                    ...tags
                }
            });
        }
    },

    captureWarning(warning) {
        if ((Env.isProduction() || this.enableInDev)) {
            SentryService.captureMessage(warning, 'warning',);
        }
    },

    captureException(error) {
        if ((Env.isProduction() || this.enableInDev)) {
            SentryService.captureException(error);
        }
    }
};

export {Sentry};
