import {APIFilterOP, APIFilters} from "@jagu/rest-api-filters-client/apiFilters";
import {flatten, unfold, setDeep} from "@/utils/object";
import {isoFromDateTime} from "@/utils/datetime";

export { APIFilterOP, APIFilterDataType, APIFilterDataTypeAllowedOperations } from "@jagu/rest-api-filters-client/apiFilters";


const APIFiltersMakeFilter = APIFilters.makeFilter;
APIFilters.makeFilter = function (filterObject) {
    const newFilter = fixEQNEQFilterUsedInDate(structuredClone(filterObject));
    return APIFiltersMakeFilter(newFilter);
};

const dateTimeColumns = [
    'created_at', 'updated_at', 'last_change', 'loaded_at', 'last_validated',
    'external_order_created_at', 'label_first_printed_at', 'last_protocol_created_at', 'sent_date'
];

/**
 * Method modifies incoming filter so that in case of equality (=== or !==) with concrete date and time -> intervals is correctly used.
 * @param filter Incoming filter that will be modified
 * @returns {*} Modified filter
 */
function fixEQNEQFilterUsedInDate(filter) {
    // Prepare filter so that can be modified.
    const flatFilter = flatten(filter);
    // Filter out paths that contain "eq."
    const pathsEQ = Object.keys(flatFilter).filter(item => { // "eq." has to be present (with dateTime) and no "neq." -> "!==" has different filtration!
            return dateTimeColumns.some(column => item.indexOf('eq.' + column) !== -1) && dateTimeColumns.every(column => item.indexOf('neq.' + column) === -1);
        });
    pathsEQ.forEach(path => {
        const pathsParts = path.split('.');
        const val = unfold(filter, path);
        const newFilterBetween = {
            [pathsParts[pathsParts.length - 1]]: [isoFromDateTime(val, "00:00:00"), isoFromDateTime(val, "23:59:59")]
        };
        setDeep(filter, pathsParts.slice(0, pathsParts.length - 2).join('.'), {
            [APIFilterOP.BETWEEN]: newFilterBetween
        });
    });
    // Filter out paths that contain "neq."
    const pathsNEQ = Object.keys(flatFilter).filter(item => { // "neq." has to be present (with dateTime)!
            return dateTimeColumns.some(column => item.indexOf('neq.' + column) !== -1);
        });
    pathsNEQ.forEach(path => {
        const pathsParts = path.split('.');
        const val = unfold(filter, path);
        const newFilterLT = { [pathsParts[pathsParts.length - 1]]: isoFromDateTime(val, "00:00:00") };
        const newFilterGT = { [pathsParts[pathsParts.length - 1]]: isoFromDateTime(val, "23:59:59") };
        setDeep(filter, pathsParts.slice(0, pathsParts.length - 2).join('.'), {
            [APIFilterOP.OR]: [
                {[APIFilterOP.LOWER_THAN]: newFilterLT},
                {[APIFilterOP.GREATER_THAN]: newFilterGT}
            ]
        });
    });
    // Return modified filter.
    return filter;
}

export {APIFilters};
