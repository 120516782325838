import {PreparePackageItemListTypes} from "@/enum/prepare_package_item_list_type";
import {ExternalOrderState, TaskState} from "@/enum/task_state";
import {TaskPriority} from "@/enum/task_priority";
import {TaskPreferredLocation} from "@/enum/task_preferred_location";
import {TaskItemsStrictMode} from "@/enum/task_items_strict_mode";
import {TaskStockTakingRestriction} from "@/enum/task_stock_taking_restriction";
import {TaskMoveProductsType} from "@/enum/task_move_products_type";
import {TaskShippingType} from "@/enum/task_shipping_type";
import {TaskItemsCardType} from "@/enum/task_items_card_type";
import {deliveryTypes} from "@/enum/delivery_type";
import {TaskExternalOrderProcessingMode} from "@/enum/task_external_order_processing_mode";

const notFoundOrACL = "A megadott feladatszámot nem találtuk vagy nincs hozzáférése a feladathoz!";
const notFoundOrACLOrItem = "A megadott feladatszám vagy tételazonosító nem található, vagy nincs hozzáférése a feladathoz!";
const approve409 = "A feladat nincs olyan állapotban, hogy elfogadható legyen!";
const reject409 = "A feladat nincs olyan állapotban, hogy elutasítható legyen!";
const notActive409 = "A feladat nincs aktív állapotban, vagy nem minden elemet dolgoztak fel!";
const memberOfSet = "Ez a művelet le van tiltva, mert a feladat egy csoport része!";

const state = {
    name: "Név",
    [TaskState.CREATED]: "Új",
    [TaskState.IN_PROGRESS]: "Folyamatban",
    [TaskState.WAITING]: "Várakozás",
    [TaskState.CLOSED]: "Befejezve",
    [TaskState.CANCELED]: "Törölve",
    [TaskState.UNASSIGNED]: "Nem hozzárendelt"
};

export default {
    name: "Feladat",
    id: "Feladat ID",
    external_id: "Külső szám",
    parent_task_id: "Felfújt",
    state: state,
    type: "Feladattípus",
    priority: {
        name: "Prioritás",
        [TaskPriority.LOW]: "Alacsony",
        [TaskPriority.NORMAL]: "Normál",
        [TaskPriority.HIGH]: "Magas",
        unknown: "Ismeretlen prioritás"
    },
    location: {
        name: "Helyszín",
        [TaskPreferredLocation.EXISTING]: "Bármilyen meglévő",
        [TaskPreferredLocation.LAST]: "Utoljára használt"
    },
    description: "Leírás",
    assigned_user: "Kijelölt",
    nobody: "<senki>",
    delivery_type: "A jövedelem típusa",
    delivery_number: "Szállítólevél szám",
    invoice_number: "Számlaszám",
    supplier_id: "Szállító",
    supplier: "Szállító",
    external_order_task_id: "Rendelési szám",
    note: "Megjegyzés",
    time_spent: "Eltöltött idő",
    ean: "EAN",
    lastScanned: "Utolsó lekérdezés",
    queue: "Sorbanállás",
    created_at: "Elkészült",
    updated_at: "Frissítve",
    viewTask: "Feladat megtekintése",
    search: "Összetett keresés",
    searchHint: "Megkeresi a feladatszámot, a szuperfeladatszámot és a külső számot.",

    noItems: "Nincsenek elemek!",
    itemsNotReadyYet: "Az elemek betöltéséhez kezdjen el dolgozni a feladaton",
    useScanner: "Használja az kódleolvasót vagy a fenti jelölőnégyzetet az elemek beolvasásához.",
    syncRunning: "Szinkronizálás folyamatban",
    generateReport: {
        pdf: "PDF-jelentés létrehozása",
        csv: "CSV-jelentés létrehozása",
        html: "HTML-jelentés létrehozása"
    },

    keep_storekeeper: "Az azonos raktárkezelőhöz rendelt feladat megtartása",

    card: {
        actions: {
            label: "Címke",
            labelDone: "A '{0}' címke nyomtatásra elküldve.",
            split: "Szétválasztani",
            splitDone: "'{0}' szétválasztva!",
            splitFail: "Nem lehet felosztani egy olyan elemet, amely csak 1 darabból áll.",
            delete: "Törlés",
            deleteDone: "'{0}' törölve!"
        },
        create: {
            product: "Készletelem létrehozása",
            location: "Hely létrehozása"
        }
    },

    attributes: {
        name: "Feladat attribútumok",
        configureFirst: "Először állítsa be a megengedett attribútumokat az almappák beállításai között",
        override: "A meglévő attribútumértékek felülírása egy másik feladattal való összevonáskor",
        created_at: "Attribútum létrehozva",

        update: {
            done: "Módosított attribútumok",
        },
    },

    attachments: {
        name: "Csatolmányok",
        filename: "Fájlnév",
        download_link: "Letöltés",
        status: {
            404: "A megadott feladatszám vagy a hozzárendelt melléklet nem található!",
            498: "A csatolt fájl feltöltése sikertelen!"
        }
    },

    notes: {
        name: "Megjegyzések",
        text: "Szöveg",
        status: {
            404: "A megadott feladatszám vagy jegyzet nem található!"
        }
    },

    filter: {
        chooseAttr: "Feladat, tulajdonság kiválasztása",
        id: {
            name: "Szám"
        },
        parent_task_id: {
            name: "Felfújt"
        },
        substock: {
            id: {
                name: 'Alraktár'
            }
        },
        external_id: {
            name: "Külső szám"
        },
        state: state,
        priority: {
            name: "Prioritás"
        },
        type: {
            name: 'Típus'
        },
        assigned_user: {
            id: {
                name: 'Hozzárendelt felhasználó'
            }
        },
        created_at: {
            name: "Létrehozva"
        },
        updated_at: {
            name: "Frissítve"
        },
        created_by: {
            name: "Létrehozta"
        },
        updated_by: {
            name: "Frissítette"
        }
    },

    timeEntries: {
        name: "Eltöltött idő",
        link: "Feladatokkal töltött idő",
        title: "Feladatokkal töltött idő",
        user: "Felhasználó",
        hours: "Órák",
        created_at: "Létrehozva",
        track: "Időmérés",
        saveTime: "Az eltöltött idő elmentése?",
        saveTimeDetails: "Egy feladat esetében futó időtartam nyilvántartása. Szeretné menteni?",
        saved: "Naplózott időtartam.",
        saveFailed: "Az eltöltött idő mentése sikertelen!",
        selectTask: "Feladat kiválasztása",
        edit: "Az időtartam változtatása",
        editDone: "Az időtartam sikeresen megváltoztatva.",
        deleteDone: "Az időtartam sikeresen törölve!",
        table: {
            user: 'Felhasználó',
            hours: "Órák",
            created_at: "Létrehozva a"
        },
        status: {
            404: "A megadott feladatszám vagy időtartam bejegyzés nem található!"
        }
    },

    chooseItems: {
        product: "Raktárkészlet",
        instance: "Különleges tétel",
        productSpecification: "A tétel specifikációja",
        quantity: "Mennyiség",
        sellPricePerUnit: "Egységenkénti eladási ár",
        priceVat: "Áfa-kulcs",
        buyPricePerUnit: 'Vételi ár egységenként',
        buyPriceVat: 'HÉA-kulcs megvásárlása',
        chooseConcreteInstance: "Válasszon ki egy adott elemet",
        itemAlreadySelected: "Ez az elem már ki van választva!",
        typeAlreadySelected: "Ez a típus már ki van választva ehhez a készletelemhez!",
        unableToRemoveLastItem: "Nem sikerült eltávolítani az utolsó elemet!",
        removeItem: "Elem eltávolítása",

        scanOrChoose: "Töltse be bármelyik elemet, vagy válasszon az alábbi listából",
        locationScanned: "Az elem helyett a helyszínt töltötte be!",
        itemNotAvailable: "Elem '{0}' nem választható!",
        itemQuantityExceeded: "Ebből a tételből maximum {0} választható!",
        itemCannotBePutOnDestination: "Ez az elem nem helyezhető el a kiválasztott célhelyen!",
        duplicityWarn: "Ez a tétel többször is szerepel. Kérjük, egyesítse a bejegyzések mennyiségét egybe, vagy távolítsa el a duplikációt.",

        items: "tételeket",
        pieces: "darabok"
    },

    strictMode: {
        label: "Szigorú",
        [TaskItemsStrictMode.FREE]: "Ingyenes",
        [TaskItemsStrictMode.NO_EXTRA]: "In extra",
        [TaskItemsStrictMode.EXACT]: "pontos",
    },

    deliveryAccept: {
        name: "Szállítmány átvéve",
        create: "Szállítási nyugta létrehozása",
        createDone: "Feladat fogadó kézbesítés létrehozva.",

        deliveryType: {
            [deliveryTypes.DELIVERY]: "Új áruk",
            [deliveryTypes.RETURN]: "Visszatérítés"
        },
        items: {
            delivered: "Kiszállított tételek",
            isCustomInstance: "Ez egy különleges eset",
            isNotCustomInstance: "Ez nem egy különleges eset"
        },

        [TaskItemsCardType.ASSIGNMENT]: "Elfogadva",

        approve: {
            name: "Létrehozza az egymásra helyezést",
        },

        acceptor: "Elfogadva",
        updateDetails: "Szállítmány átvételi feladat szerkesztése",
        newTask: "Az új tárolási feladat paraméterei",
        status: {
            404: notFoundOrACL
        }
    },

    stock_loading_priority: "Prioritás",
    stock_loading_preferred_location: "Választott elhelyezés",
    stock_loading_description: "Leírás",
    stock_loading_stock_id: "Készlet",
    stock_loading_subordinate_stock_id: "Készlet alkategória",
    stock_loading_strict_mode: "Szigorú",
    stockLoading: {
        name: "Bevételezés",
        create: "Bevételezésozó feladat létrehozása",
        createDone: "Feladat létrehozva.",

        priority: "Prioritás",
        preferred_location: "Kedvelt elhelyezés",
        description: "Leírás",
        stock_id: "Készlet",
        subordinate_stock_id: "Készlet alkategória",
        supplier_id: "Szállító",
        delivery_number: "Szállítólevél száma",
        invoice_number: "Számlaszám",
        strict_mode: "Szigorú",
        itemsToLoad: "Készleten tartandó tételek",

        scanLocation: "Először keresse meg azt a helyet, ahová az elemet szeretné elhelyezni",
        scanSomeProducts: "A bevételezés befejezése sikertelen.",
        assignAllLocations: "Az összes tárolandó elem célhelyének beállítása.",
        chooseProductForLocation: "Először válassza ki azt az elemet, amelyhez ezt a helyet szeretné beállítani.",
        locationNotAllowed: "Ez a kiválasztott tárolóhely nem engedélyezett!",
        notToBeLoaded: "A beolvasott elemet nem szabad betölteni!",
        loadedLimit: "Terhelhetőségi határérték túllépve: {0}/{1}.",
        onlyItemsFromInventory: "Nem mozgathatsz több bábut egy helyre, mint amennyi jelenleg a mozgásban van!",
        noItemsCanBePutOnLocation: "A feladatból nem helyezhetők el elemek a kiválasztott helyre! Próbáljon meg néhány tárgyat bepakolni a mozgatásba.",
        itemCanNotBePutOnLocation: "A betöltött elem nem helyezhető el a kiválasztott helyre!",

        loadTo: "Feltöltés ide",
        chooseInstanceAndHowMuch: "A címkék nyomtatásához válasszon ki egy adott tételt és darabszámot.",
        chooseHowMuch: "A '{0}' címkék nyomtatásához válassza ki a darabszámot.",
        rightProductWrongInstanceScanned: "Nem a megfelelő elemet töltötte be.",
        lookForAnotherCode: "Ha nem a megfelelő elemet töltötte be, próbáljon másik vonalkódot találni az elemen.",

        [TaskItemsCardType.TO_MOVE]: "Betöltendő",
        [TaskItemsCardType.IN_INVENTORY]: "Elemek áthelyezése folyamatban",
        [TaskItemsCardType.MOVED]: "Áthelyezve",
        [TaskItemsCardType.ASSIGNMENT]: "Betöltendő",

        returnTo: {
            [TaskItemsCardType.TO_MOVE]: "Visszatérés a raktárkészlethez",
            [TaskItemsCardType.IN_INVENTORY]: "Visszatérés a tranzittárgyakhoz",
        },

        strictHint: {
            [TaskItemsStrictMode.FREE]: "A meghirdetett elemek csak ajánlások, bármit lehet halmozni.",
            [TaskItemsStrictMode.NO_EXTRA]: "Csak bejelentett tételeket szabad egymásra rakni, néhány hiányozhat vagy kevesebb lehet.",
            [TaskItemsStrictMode.EXACT]: "Csak bejelentett tételeket szabad egymásra rakni, egyik sem hiányozhat, és a megadott mennyiségnek meg kell lennie.",
        },

        confirmDelete: {
            fromInventory: "a mozgásból",
            fromDestination: "a helyszínről"
        },

        navigation: {
            assignment: "Megbízás",
            stockLoading: "Feltöltés",
            finish: "Befejezés",
            buyPrices: "A beszerzési árak meghatározása"
        },
        buyPrices: {
            title: "Vásárlási árak",
            instance: "Raktárkészlet",
            manufacturer: {
                id: "Gyártó"
            },
            measure_unit: "Mérési egység",
            price: "Vételár egységenként",
            vat: "Áfa-kulcs",
            batchMultiply: {
                by: "Együttható",
                action: "Szorozza meg a szűrt árakat",
                done: "Az árak frissültek",
                failed: "A frissítés sikertelen!"
            },
            negativeNotAllowed: "Az árváltoztatás nem sikerült! A negatív vételárat nem lehet megtakarítani.",
            status: {
                404: notFoundOrACLOrItem,
                409: approve409
            }
        },
        items: {
            status: {
                404: notFoundOrACLOrItem,
                409: "A tétel sorszáma már máshol használatban van, vagy a feladat nincs aktív állapotban!"
            },
            update: {
                status: {
                    404: notFoundOrACLOrItem,
                    409: notActive409
                }
            }
        },
        inventory: {
            status: {
                404: notFoundOrACLOrItem,
                409: "A megadott sorozatszámú tétel már létezik, vagy a hely nem engedélyezett az aktuális alraktárban."
            }
        },
        status: {
            404: notFoundOrACL,
            409: "A megadott sorozatszámú termék már más raktárban van tárolva, vagy már raktáron van."
        }
    },

    stockTaking: {
        name: "Leltár",
        create: "Leltárfeladat létrehozása",
        createDone: "Leltározási feladat létrehozva.",

        priority: "Prioritás",
        description: "Leírás",
        restrict_type: "Korlátozás típusa",
        restrict_references: "A korlátozás jellege",
        product: "Készletelem",
        stock_id: "Készlet",
        attachments: "Mellékletek",

        scanLocation: "Először töltse be azt a helyet, ahol az elem található.",
        noItems: "Nincs leltárba vett tárgy!",
        substockAccessForbidden: "Ezt a feladatot nem rendelheti ki magának, mivel nem fér hozzá minden albolthoz.",

        entityHelper: {
            label: "Használja a keresőmotort több korlátozás tömeges kiválasztásához a {0} számára. Írja be a keresett kifejezést, ellenőrizze a szűrt elemeket a korlátozásokért, majd kattintson az 'Állítsa be ezeket a korlátozásokat a következőhöz: {0}'",
            hint: "A mező bármilyen reguláris kifejezést elfogad",
            set: "Állítsa be ezeket a korlátozásokat a következőhöz: {0}"
        },

        navigation: {
            assignment: "Megbízás",
            stockTaking: "Leltár",
            finish: "Befejezés"
        },

        restrictions: {
            [TaskStockTakingRestriction.STOCK]: 'Készlet',
            [TaskStockTakingRestriction.STOCK_OWNER]: "Raktártulajdonos",
            [TaskStockTakingRestriction.MANUFACTURER]: "Gyártó",
            [TaskStockTakingRestriction.PRODUCT]: "Készletelem",
            [TaskStockTakingRestriction.PRODUCT_INSTANCE]: 'Különleges tétel',
            [TaskStockTakingRestriction.STOCK_LOCATION]: "Az elem tárolásának helye",
            [TaskStockTakingRestriction.SUB_STOCK]: "Alraktár",
            wrongLocation: 'Ez a hely nem leltározható!'
        },

        [TaskItemsCardType.PRESENT]: 'Talált elemek',
        [TaskItemsCardType.ASSIGNMENT]: "Feltételezett státusz:",

        items: {
            partOfInventory: {
                yes: {
                    [TaskStockTakingRestriction.MANUFACTURER]: 'Leltárköteles termelői tételek',
                    [TaskStockTakingRestriction.PRODUCT]: 'Leltárköteles készlettételek',
                    [TaskStockTakingRestriction.PRODUCT_INSTANCE]: 'Leltárköteles készlettételek',
                    [TaskStockTakingRestriction.STOCK_LOCATION]: 'Ez a tétel nem lehet azon a helyen'
                },
                no: {
                    [TaskStockTakingRestriction.MANUFACTURER]: 'Ennek a terméknek a gyártója nem leltározható!',
                    [TaskStockTakingRestriction.PRODUCT]: 'Ez a készleten lévő tétel nem leltározható!',
                    [TaskStockTakingRestriction.PRODUCT_INSTANCE]: 'Ez a készletcikk nem leltározható!',
                    [TaskStockTakingRestriction.STOCK_LOCATION]: 'Ez a tétel nem lehet azon a helyen'
                }
            },
            status: {
                404: notFoundOrACLOrItem,
                409: notActive409
            },
        },
        status: {
            404: notFoundOrACL
        }
    },

    stockPickingSet: {
        name: "Gyűjtő szedés",
        create: "Gyűjtő szedés létrehozása",
        createDone: "Gyűjtő szedés sikeresen létrehozva",
        stockPickingTasks: "Szedésó feladat létrehozása",
        usedStrategy: "Korábban létrehozott stratégia",
        pickingAlreadySelected: "Ez a feladat már ki van választva!",
        unableToRemoveLastItem: "Nem sikerült eltávolítani az utolsó elemet!",
        stockPickingLocationFilled: "Célhelyszínnel",
        stockPickingLocationBlank: "célhely nélkül",
        itemAlreadySelected: "Ismétlődő elem!",
        strategies: {
            SAME_SINGLE_PRODUCT: "Ugyanaz az elem",
            SINGLE_PRODUCT: "Egy elem",
            IDENTICAL_ORDERS: "Ugyanaz",
            FROM_OLDEST: "A legrégebbitől"
        },
        delete: {
            title: "Csoport feloldása és gyüjtő szedés eltávolítása",
            deleteOne: "Eltávolítja az átvételt a csoportból, az átvétel a feladatáttekintésben marad későbbi feldolgozás céljából",
            status: {
                404: notFoundOrACL,
                409: "Az eltávolítási csoport más objektumokkal való kapcsolata miatt nem törölhető!"
            }
        },
        items: {
            status: {
                404: notFoundOrACLOrItem
            }
        },
        inventory: {
            status: {
                404: notFoundOrACLOrItem,
                409: "Az elem nincs a megadott helyen, a hely nem engedélyezett a kiválasztott alraktár részére, vagy elérte a áthelyezési limitet."
            }
        },
        location: {
            status: {
                409: "Nem lehet megváltoztatni a célhelyet - a kiválasztott helyen már vannak elemek!"
            }
        },
        status: {
            404: notFoundOrACL,
            409: "A kiválasztott feladatok nem egyesíthetőek."
        },
        advancedErrorMessages: {
            invalid_task_state: 'A feladat nem érvényes állapotban van ennek a műveletnek a végrehajtásához. A feladat állapota {0}',
            location_not_allowed: 'Ez a hely nem engedélyezett a jelenlegi alárendelt készlethez.',
            missing_location: 'Az általad megpróbált hely használata érvénytelen vagy nem létezik.',
            movement_failed: 'Az átrakás sikertelen volt, nincs elegendő darabszámú tétel a forráshelyen.' +
                ' Költözni akar {0}.',
            task_not_found: 'A feladat nem található vagy a feladat lezárása nem sikerült.',
            invalid_moving_quantity: 'Az áthelyezni kívánt tételek mennyisége nagyobb, mint a forráshelyen lévő mennyiség.' +
                ' Költözni akar {0}. A helyszínen {1}.'
        }
    },

    stockPicking: {
        name: "Szedés",
        create: "Szedésó csoport létrehozása",
        createDone: "Rakodó csoport sikeresen létrehozva",
        createDoneAdded: "Szedés a #{0} feladathoz sikeresen hozzáadva",

        priority: "Prioritás",
        description: "Leírás",
        stock_id: "Készlet",
        subordinate_stock_id: "Alraktér",
        tasks: "Feladatok",
        shipping_type: "A metódus befejezése",
        destination_stock_location_id: "A szállítás célpontjának kiválasztása",
        destination_stock_location_id_hint: "Az áruszállítás cél helyszíne, ahová a szállítás történik",
        buyer_id: "Vevő",
        buyer_delivery_address_id: "A vevő szállítási címe",
        destination_location_id: "Szállítási hely",
        partOfSet: "A csoport része",
        external_order_id: "Megrendelés",

        navigation: {
            assignment: "Megbízás",
            stockPicking: "Szedés",
            finish: "Befejezés",
            movements: "Áthelyezések"
        },

        chooseTargetLocation: "Válasszon egy szállítási célpontot!",
        scannedLocationNotEmpty: "A beolvasott hely nem üres, továbbra is ki akarja választani célállomásként?",
        notToBeMoved: "A betöltött elem nem áthelyezhető vagy már át lett helyezve!",
        notInInventory: "A betöltött elem nem áthelyezhető!",
        unableToFinishDueItems: "A feladatot nem lehetett befejezni, mert nem az összes kért eleme áthelyezhető!",
        lookForAnotherCode: "Ha nem a megfelelő elemet töltötte be, próbáljon másik vonalkódot találni az elemen.",

        type: {
            label: "Átvételi módszer",
            [TaskShippingType.COURIER]: "Feladás",
            [TaskShippingType.PERSONAL_COLLECTION]: "Közvetlen átadás az ügyfélnek"
        },

        yetUnknown: "még nem kiválasztott helyszín",
        handOverTo: "átadás az előfizetőnek",

        autoMoveInProgress: "A már a célhelyen lévő elemek automatikus áthelyezése folyamatban van.",

        itemsToPick: "Áthelyezendő elemek",

        [TaskItemsCardType.TO_MOVE]: "Még eltávolítandó",
        [TaskItemsCardType.IN_INVENTORY]: "Áthelyezésben lévő elemek",
        [TaskItemsCardType.MOVED]: "Átmozgatva",
        [TaskItemsCardType.ASSIGNMENT]: "Eltávolítandó",
        pickedToHandover: "Készen áll az ügyfélnek történő átadásra",

        items: {
            product: "Raktárkészlet",
            quantity: "Mennyiség",
            status: {
                404: notFoundOrACLOrItem,
                409: "A feladat nincs olyan állapotban, hogy a tétel eladási ára szerkeszthető legyen!"
            }
        },
        inventory: {
            status: {
                404: notFoundOrACLOrItem,
                409: "Az elem nincs a megadott helyen, a hely nem engedélyezett a kiválasztott alraktár számára, vagy az áthelyezési limitet elértük."
            }
        },
        location: {
            status: {
                409: "Nem lehet megváltoztatni a célhelyet - a kiválasztott helyen már vannak elemek!"
            }
        },

        status: {
            404: notFoundOrACL,
            409: "A kért cikkekből nincs elég a raktárban."
        }
    },

    preparePackage: {
        name: "Csomagolás",
        order: "Megrendelés",
        subordinate_stock_id: "Alraktér",
        location: "Készletelem helye",
        disengagePackingMode: "Vissza a rendelés összefoglalójához",

        navigation: {
            assignment: "Megbízás",
            preparePackage: "Csomagolás",
            finish: "Befejezés"
        },

        scanAnything: "Kezdje a csomagolatlan tárgyak beolvasásával",
        scannedUnknown: "A beolvasott elem nem része semmilyen megrendelésnek!",
        scannedUnknownOrTooMuch: "A beolvasott elem nem szerepel egyetlen rendelésben sem, vagy legalábbis nem ebben a mennyiségben!",
        scannedWrongItem: "A beolvasott tétel nem része ennek a megrendelésnek!",
        scannedTooMuchItem: "Ez a rendelés ebből a tételből kisebb mennyiséget tartalmaz!",

        ean_read_required: "Ebben az alraktárban ki kell választani az olvasó által becsomagolandó összes tételt",
        auto_packed_rest: "A rendelés automatikusan be lett csomagolva!",
        haveYouPackedAllPieces: "Minden darab be van csomagolva?",
        packAllPieces: "Csomag {0}",

        orders: {
            label: "Rendelések",
            order_id: 'Rendelési',
            external_order_id: "Külső rendelési azonosító",
            external_order_note: "Megjegyzés",
            printInvoice: 'Számla nyomtatása',
            printInvoiceAmount: {
                1: "egy",
                2: 'kettő'
            },
            invoiceCopies: "másolatok",
            unknown: "Ismeretlen rendelés!",
            cancelled: "A rendelést törölték!"
        },

        shipments: {
            carrier: {
                label: "Szállító"
            },
            lastValidated: "Utolsó pontossági ellenőrzés",
            never: "<soha>",
            valid: "A küldeményt el lehet küldeni",
            notValid: "A küldeményt nem lehet küldeni",
            notValidatedYet: "A küldemény feladhatóságát még nem ellenőrizték.",
            errorMessages:  "Hibaüzenetek",
            package_count: "Csomagok száma",
            total_value: "Teljes ár",
            total_weight: "Teljes súly",
            change: {
                total_value: "Teljes ár módosítása",
                total_weight: "A küldemény teljes súlyának módosítása"
            },
            print: 'Nyomtatás',
            label: "címke",
            deliveryNote: "szállítólevél",
            printLabelAfterAllItemsPacked: 'A címkét csak azután nyomtassa ki, hogy a rendelés összes tételét becsomagolták.',
            printLabelPending: 'A hordozó címkéjének lekérése folyamatban van ...',
            labelPrintedAlready: 'A küldemény címkéje már kinyomtatásra került. Ha valóban szüksége van a címke újbóli nyomtatására, kérjük, forduljon a raktárvezetőhöz.'
        },

        items: {
            type: {
                [PreparePackageItemListTypes.TODO]: 'Csomagolandó',
                [PreparePackageItemListTypes.DONE]: 'Csomagolva',
                [PreparePackageItemListTypes.ALL]: "Megrendelt tételek"
            }
        },

        packing: {
            status: {
                404: notFoundOrACL,
                409: "Ez a tétel nem tartozik a kiválasztott rendeléshez, vagy már megfelelő mennyiség van csomagolva van belőle!"
            }
        },

        status: {
            404: notFoundOrACL,
        }
    },

    moveProducts: {
        name: "Elemek áthelyezése",
        create: "Áthelyezési feladat létrehozása",
        createDone: "Az áthelyezési feladat létrehozva.",
        type: {
            label: "Move type",
            [TaskMoveProductsType.COLLAPSE]: "Áthelyezés tetszőleges helyről {1}-be",
            [TaskMoveProductsType.DISTRIBUTE]: "Az elemek szétosztása {0}-ből tetszőleges helyre",
            [TaskMoveProductsType.RELOCATE]: "Áthelyezzük az elemeket {0}-ről {1}-re",
            [TaskMoveProductsType.MANYTOMANY]: "Költözz bárhonnan bárhová",
        },

        priority: "Prioritás",
        description: "Leírás",
        stock_id: "Készlet",
        subordinate_stock_id: "Alraktár",
        source_location_id: "Forrás helye",
        destination_location_id: "célhely",
        transfer_mode: "Szigorú",
        attachments: "Mellékletek",

        anyLocation: "<minden hely>",
        scanSourceOrDestLocation: "Kérjük, olvassa be a megfelelő forrás- vagy célhelyet: {0} / {1}!",
        notToBeMoved: "A betöltött elemet nem lehet áthelyezni, vagy már áthelyezték!",
        notInInventory: "A betöltött elemet nem lehet áthelyezni!",
        unableToFinishDueItems: "A feladatot nem lehetett befejezni, mert nem helyezte át az összes kért elemet!",

        unableToMoveAnyToAny: "Nem tudok semmilyen helyről sehová áthelyezni. Válasszon ki legalább egy helyet.",
        unableToMoveToSame: "Nem lehet ugyanarra a helyre áthelyezni. Válasszon másik forrás- vagy célhelyet.",
        locationNotAllowed: "A kiválasztott hely nem engedélyezett a kiválasztott alraktárban!",
        notEnoughItems: "Ebből a tételből nincs elég a kiválasztott helyen!",
        noItemsCanBePutOnLocation: "A feladatból nem helyezhetők el elemek a kiválasztott helyre!",
        itemCanNotBePutOnLocation: "A betöltött elem nem helyezhető el a kiválasztott helyre!",
        alreadyUsedAsDestination: "Egy elemet már elhelyeztek ezen a helyen! Vidd vissza a már elköltözöttből.",
        alreadyUsedAsSource: "Az elemet erről a helyről gyűjtötték össze!",

        [TaskItemsCardType.TO_MOVE]: "Még hátralévő áthelyezés",
        [TaskItemsCardType.IN_INVENTORY]: "Áthelyezés folyamatban",
        [TaskItemsCardType.MOVED]: "Sikeresen áthelyezve",
        [TaskItemsCardType.ASSIGNMENT]: 'Áthelyezendő',

        strictHint: {
            [TaskItemsStrictMode.FREE]: "A megadott elemek csak ajánlások, bármi áthelyezhető.",
            [TaskItemsStrictMode.NO_EXTRA]: "Csak meghatározott elemek helyezhetők át, előfordulhat, hogy néhány hiányzik vagy kevesebb.",
            [TaskItemsStrictMode.EXACT]: "Csak az értesített tételek helyezhetők át, egyik sem hiányozhat, és a megadott mennyiségnek kell lennie.",
        },

        navigation: {
            assignment: "Megbízás",
            moveProducts: "áthelyezés",
            finish: "Befejezés",
            movements: "Áthelyezések"
        },

        items: {
            productsToMove: "Áthelyezendő tételek",
            status: {
                404: notFoundOrACLOrItem,
                409: "Nem sikerült új elemet hozzáadni az áthelyezéshez!"
            }
        },
        inventory: {
            status: {
                404: notFoundOrACLOrItem,
                409: "Az elem nincs a megadott helyen, a hely nem engedélyezett a kiválasztott alraktár számára, vagy az áthelyezési limitet elértük."
            }
        },
        finish: {
            status: {
                404: notFoundOrACL,
                409: "A feladat nincs aktív állapotban, vagy vannak elhelyezetlen elemek!"
            }
        },
        status: {
            404: notFoundOrACL,
            409: "A forráshelyen vagy az alraktárban nincs elég a szükséges elem.",
        }
    },

    locationTransfer: {
        name: "Helyszínátvitel",
        create: "Helyszínátadási feladat létrehozása",
        createDone: "A helyátadási feladat létrehozva.",

        priority: "Prioritás",
        description: "Leírás",
        stock_id: "Készlet",
        allow_filled_locations: "Engedélyezi a már betöltött helyekre történő mozgásokat",
        attachments: "Mellékletek",

        scanLocation: "Helyszín betöltése! Nem tölthet be egyes elemeket egy teljes helyváltoztatás során!",
        scanningTo: "A hely betöltése, ahová az összes elemet áthelyezzük.",

        canNotUseSame: "Már kiválasztott forrás!",
        canNotUseNotEmpty: "A hely nem üres!",

        confirmTitle: "Megerősítés {0} tétel ({1} db) áthelyezése a '{2}' helyről a '{3}' helyre",
        confirmWithReader: "A megerősítéshez a '{0}' kódot is újratöltheti",
        confirmButton: "Megerősítés",

        moved: "Indítványozta",
        noneMoved: "Nem helyeztek át helyszíneket",

        navigation: {
            assignment: "Hozzárendelés",
            locationTransfer: "Mozgás",
            finish: "Befejezés"
        },

        status: {
            404: notFoundOrACL,
        }
    },

    substockTransfer: {
        name: "Áthelyezés alraktárak között",
        create: "Feladat létrehozása az alraktárok közötti áthelyezéshez",
        createDone: "Az alraktárok közötti áthelyezési feladat létrejött.",

        priority: "Prioritás",
        description: "Leírás",
        stock_id: "Készlet",
        source_subordinate_stock_id: "Forrás alraktárok",
        destination_subordinate_stock_id: "Cél alraktár hely",
        attachments: "Mellékletek",

        unableToMoveToSame: "Nem lehet ugyanabba az álraktárba áthelyezni. Válasszon ki egy másik forrás- vagy cél alraktárt.",
        notEnoughItems: "Ebből az elemből nincs elég a kiválasztott alraktárban!",
        noItemsCanBePutOnLocation: "A feladatból nem helyezhetők el elemek a kiválasztott helyre!",
        itemCanNotBePutOnLocation: "A betöltött elem nem helyezhető el a kiválasztott helyre!",

        from: "Áthelyezés innen",
        to: "Áthelyezés ide",

        notToBeMoved: "A betöltött elemet nem lehet áthelyezni, vagy már áthelyezték!",
        notInInventory: "A betöltött elemet nem lett áthelyezve!",

        autoMoveInProgress: "A már a célhelyen lévő elemek automatikus áthelyezése folyamatban van.",

        [TaskItemsCardType.TO_MOVE]: "Áthelyezendő elemek",
        [TaskItemsCardType.IN_INVENTORY]: "Áthelyezés folyamatban",
        [TaskItemsCardType.MOVED]: "Áthelyezve",
        [TaskItemsCardType.ASSIGNMENT]: 'Áthelyezendő',

        isAutocompleted: "A feladat automatikusan befejeződött, mivel a cél alraktár ugyanazokat a műveleteket engedélyezi, mint a forrás alraktár.",

        navigation: {
            assignment: "Megbízás",
            transferProducts: "Áthelyezés",
            finish: "Befejezés",
            movements: "Áthelyezések"
        },

        items: {
            productsToMove: "Átrakodásra váró tételek",
            status: {
                404: notFoundOrACLOrItem
            }
        },
        inventory: {
            status: {
                404: notFoundOrACLOrItem,
                409: "Az elem nincs a megadott helyen, a hely nem engedélyezett a kiválasztott alraktár számára, vagy a átrakodási limitet elértük."
            }
        },
        status: {
            404: notFoundOrACL,
            409: "Rossz forrás- és célhelyet választott ki, vagy nincs elég szükséges elem a forráshelyen vagy az alraktárban."
        }
    },

    externalOrder: {
        name: "Külső megrendelés",
        create: "Hozzon létre egy külső rendelést",
        createDone: "Külső rendelés létrehozva.",

        priority: "Prioritás",
        description: "Leírás",
        stock_id: "Készlet",
        subordinate_stock_id: "Alraktár",
        buyer_id: "Ügyfél",
        buyer_contact: "Egyéb ügyféladatok",
        buyer_delivery_address_id: "Szállítási cím",
        processing_mode: "Megrendelés feldolgozási módja",
        carrier_id: "Szállító",
        carrier_service_id: "Fuvarozói szolgáltatás",
        carrierServiceParameters: {
            title: "Fuvarozói szolgáltatási paraméterek",
            name: "Név",
            value: "Érték"
        },

        id: "Rendelési szám",
        carrier: "Szállító",
        quantity: "Megrendelt mennyiség",
        satisfied_quantity: "Feldolgozott mennyiség",
        reserved_quantity: "Fenntartott mennyiség ehhez a rendeléshez",
        available: "Elérhető mennyiség",
        toBePicked: "Eltávolítandó mennyiség",
        product: {
            name: "Termék"
        },
        stock_picking_task_ids: "Felvétel",
        prepare_shipment_package_task_ids: "Csomagolás",
        shipment_ids: "Szállítmányok",
        selectedInstance: "Kiválasztott elemek, amelyet fel kell venni",
        default: "<default>",
        external_author_id: "Külső megrendelés szerzőjének azonosítója",
        external_author_name: "Külső megrendelés szerző neve",
        external_note: "Külső rendelési megjegyzés",
        externalFields: {
            label: "Rendelési paraméterek",
            author_id: "Szerző azonosító",
            author_name: "Szerző neve",
            id: "Azonosító",
            note: "Jegyzet",
            order_created_at: "Létrehozva:",
            order_id: "Külső rendelési szám"
        },
        searchHint: "Keresi a munkaszámot és a rendelésszámot",

        state: {
            label: "Rendelés állapota",
            [ExternalOrderState.EO_STATE_CREATED]: "Új",
            [ExternalOrderState.EO_STATE_UNABLE_TO_PROCESS]: "Szállítmány fuvarozó API hibák",
            [ExternalOrderState.EO_STATE_IN_PROGRESS]: "Feldolgozás alatt",
            [ExternalOrderState.EO_STATE_WAITING]: "Várakozás a részfeladatokra",
            [ExternalOrderState.EO_STATE_STOCK_PICKING_WAITING]: "Kiválasztásra vár",
            [ExternalOrderState.EO_STATE_STOCK_PICKING_IN_PROGRESS]: "Kiválasztás alatt",
            [ExternalOrderState.EO_STATE_STOCK_PICKING_COMPLETED]: "Kiválasztás kész",
            [ExternalOrderState.EO_STATE_STOCK_PICKING_CANCELED]: "Komissiózás megszakítva",
            [ExternalOrderState.EO_STATE_PREPARE_PACKAGE_WAITING]: "Csomagolásra vár",
            [ExternalOrderState.EO_STATE_PREPARE_PACKAGE_IN_PROGRESS]: "Csomagolva",
            [ExternalOrderState.EO_STATE_PREPARE_PACKAGE_COMPLETED]: "Csomagolás kész",
            [ExternalOrderState.EO_STATE_PREPARE_PACKAGE_CANCELED]: "Csomag törölve",
            [ExternalOrderState.EO_STATE_SHIPMENTS_WAITING]: "A szállítmányok átadásra várnak",
            [ExternalOrderState.EO_STATE_SHIPMENT_CANCELLED]: "Szállítás törölve",
            [ExternalOrderState.EO_STATE_SHIPPED]: "Szállítmányozóval",
            // More detailed EO_STATE_SENT
            [ExternalOrderState.EO_STATE_SENT]: "Szállítmány elküldve",
            [ExternalOrderState.EO_STATE_BEING_DELIVERED]: "Kézbesítés alatt",
            [ExternalOrderState.EO_STATE_DELIVERED_TO_PICKUP_LOCATION]: "Átvételi helyre szállítva",
            [ExternalOrderState.EO_STATE_LOST]: "Elveszett szállítmány",
            // end of EO_STATE_SENT details
            [ExternalOrderState.EO_STATE_DELIVERED]: "Kézbesített szállítmányok",
            [ExternalOrderState.EO_STATE_RETURNING]: "Visszatérő szállítmányok",
            [ExternalOrderState.EO_STATE_RETURNED]: "A szállítmányok visszaküldése",
            [ExternalOrderState.EO_STATE_CANCELLED]: "Törölve",
            [ExternalOrderState.EO_STATE_CLOSED]: "Zárva"
        },

        processingMode: {
            label: "Megrendelés feldolgozási módja",
            changeRequired: "Ha szeretné feldolgozni a megrendelést, változtassa meg a feldolgozás módját.",
            [TaskExternalOrderProcessingMode.PROCESS_TOGETHER]: "Folyamat együtt",
            [TaskExternalOrderProcessingMode.PROCESS_AVAILABLE]: "Feldolgozza a rendelkezésre álló",
            [TaskExternalOrderProcessingMode.DO_NOT_PROCESS]: "Ne dolgozza fel",

            hint: {
                [TaskExternalOrderProcessingMode.PROCESS_TOGETHER]: "Várjuk az összes megrendelt termék raktárkészletének rendelkezésre állását.",
                [TaskExternalOrderProcessingMode.PROCESS_AVAILABLE]: "Amint egyes tételek raktáron vannak, létrejön egy pick.",
                [TaskExternalOrderProcessingMode.DO_NOT_PROCESS]: "A megrendelés nem kerül feldolgozásra, nem jön létre komissiózás."
            }
        },

        createStockPicking: "Új rakodási feladat részletei",
        approveNotPossible: "A 'Ne dolgozza fel' státuszban lévő megrendelés nem fogadható el",
        someItemsNotAvailable: "A megrendelés 'Folyamat együtt' állapotban van, de nem minden tétel érhető el",

        navigation: {
            assignment: "Megbízás",
            order: "Megrendelés",
            finish: "Befejezés"
        },

        update: {
            status: {
                409: "Csak az 'Új' státuszú megrendelések módosíthatók!"
            }
        },

        process: {
            status: {
                404: notFoundOrACL,
                409: "A feladat nincs jóváhagyási státuszban, vagy több terméket kértek, mint amennyi raktáron van!"
            }
        },

        approve: {
            name: "Válogatás létrehozása",
        },

        close: {
            label: "Megrendelés lezárása",
            note: "Megjegyzés",
            shipment_state: "Új szállítmány állapota",
            done: "#{0} {1} zárva, a szállítmányok a következőre vannak állítva '{2}'"
        },

        items: {
            title: "Rendelt tételek",
            status: {
                404: notFoundOrACLOrItem,
                409: "A feladat nincs abban az állapotban, hogy törölje az elemet!"
            }
        },

        show: {
            label: "Vevői megrendelések"
        }
    },

    productsReservation: {
        name: "Foglalás",
        create: "Foglalás létrehozása",
        createDone: "Foglalás létrehozva.",

        priority: "Prioritás",
        description: "Leírás",

        id: 'Foglalás száma',
        quantity: "Foglalás mennyisége",
        externalFields: {
            label: "Foglalás paraméterek",
            author_id: "Szerző azonosítója",
            author_name: "Szerző neve",
            id: "Azonosító",
            note: "Megjegyzés",
            order_created_at: "Létrehozva",
            order_id: "Sorszám"
        },
        searchHint: "Feladat- és foglalásszám keresése",

        [TaskItemsCardType.ASSIGNMENT]: "Fenntartott",
    },


    itemsCard: {
        canBeFoundAt: "Az elem megtalálható a következő címen:",
        canBePutOn: "Az elem elhelyezhető:",
        alreadyPlacedAt: "Az elem már be van kapcsolva:",
        pickedUpFrom: "Az elemet a következő helyről vették fel:",

        updateQuantity: {
            notEnoughItemsInInventory: "Nem mozgathatsz több darabot egy helyre, mint amennyi éppen a mozgásban van!",
            notEnoughItemsOnSource: "Nincs elég elem a forráshelyen a mozgatáshoz!",
            itemNotPickedFromThisLocation: "Az árucikket nem gyűjtötték be az adott helyről!",
            notSoManyItemsPickedFromSource: "Nem sok elemet vittek el a forrás helyszínéről!",
            destinationLimit: "Az áthelyezési limitet túllépte: {0}/{1}.",
        },

        returnAmount: "Visszatenni {0} {1}",
        returnWhere: "visszatenni ide {0}",
        returnToInventory: "áthelyezendő lévő elemek között",
        returnTo: {
            location: "Vissza az eredeti helyre",
            inventory: "Visszatérés az áthelyezendő elemekhez"
        },
        remove: "Eltávolítás",

        from: "a  címről",
        fromAnywhere: "bárhonnan",
        to: "",
        toAnywhere: "bárhová",
        substockLocations: "A cél alraktárhoz való áthelyezés engedélyezett",

        scanLocation: "Kezdje a hely beolvasásával.",
        scanPackingLocation: "Kezdje a {0} csomagolási hely beolvasásával",
        scanOrderItem: "Bármely elem beolvasása",
        scanLocationOrItem: "Először szkenneljen be egy helyszínt vagy elemet.",
        scanningFrom: "Az átvenni kívánt elem betöltése",
        scanningTo: "Elem betöltése ide",
        scanningOrder: "Következő tétel betöltése a #{0} rendelésből",
        scannedExpeditionLocation: "Biztos, hogy be akarod tölteni a mobil áthelyezést?",
        scannedMobileLocation: "Biztos, hogy be akarod tölteni a mobil áthelyezést?",
        scanConfirm: "Betöltés {0}",
        cancelByScanning: "Vagy töltsön be egy másik kódot",
        confirmByScanning: "Vagy olvassa be újra a {0} kódot",

        moreDetails: "További részletek",
        changeTargetLocation: "Célhely megváltoztatása",
        cannotChangeTargetLocation: "Nem lehet megváltoztatni a célhelyet, először az aktuális helyről kell visszavenni az elemeket!",

        moveEverything: "Mozgás lehetséges a ",
        moveEverythingInProgress: "Minden olyan elem, amely a célhelyen elhelyezhető, áthelyezésre kerül."
    },

    locationsCard: {
        canBePutOn: "A helyszínre lehet fektetni a feladatból:",
        cannotBePutOnThisLocation: "Ez az elem nem helyezhető el ezen a helyen!",
        cannotBePutOnAnyLocation: "Ez a tétel nem helyezhető el a feladat bármelyik helyén!",
        reloadAllowedLocations: "Cache frissítése"
    },


    itemPick: {
        inStock: "raktáron",
        inProgress: "a megadott feladatok folyamatban",
        available: "elérhető",
    },

    unableToLoad: "A feladatlista nem tölthető be. Kérjük, próbálja meg később újra.",

    approve: {
        name: "Elfogadom",
        done: "#{0} {1} elfogadva",
        doneTotal: "#{0} {1} befejezve",
        status: {
            404: notFoundOrACL,
            409: approve409,
            423: memberOfSet
        }
    },
    cancel: {
        name: "Törölje",
        done: "#{0} {1} törölve",
        status: {
            404: notFoundOrACL,
            409: "A feladat nincs olyan állapotban, amelyből törölni lehetne!"
        }
    },
    reject: {
        name: "Visszautasítás",
        done: "#{0} {1} elutasítva",
        status: {
            404: notFoundOrACL,
            409: reject409,
            423: memberOfSet
        }
    },
    assign: {
        name: "Hozzárendelés",
        self: "Kezdj el dolgozni a feladaton",
        notAssignable: "Nem dolgozhatsz a felszedővel, mert az a csoport része!",
        useReader: "A feladat futtatásához az olvasó segítségével is betölthet egy tetszőleges kódot",
        justAssign: {
            self: "Adjon ki magának feladatot"
        },
        done: "#{0} {1} hozzárendelt",
        status: {
            404: notFoundOrACL,
            403: "A feladat már hozzá van rendelve, vagy nincs hozzáférése a célrészkészlethez!",
            423: memberOfSet
        }
    },
    startProcessing: {
        self: "Kezdj el dolgozni a feladaton",
        useReader: "A feladat futtatásához az olvasó segítségével is betölthet egy tetszőleges kódot",
        done: "#{0} {1} folyamatban lévő munka",
        status: {
            409: "Nem tudod feldolgozni a feladatot, mert nincs hozzárendelve!"
        }
    },
    unassign: {
        name: "Hozzárendelés megszüntetése",
        disabled: "Egy feladatot nem lehet feloldani, amíg vannak elemek a mozgatásban.",
        done: "#{0} {1} kijelölt",
        status: {
            404: "A megadott feladatszám nem található!",
            409: "Nincs feladatod!",
            423: "A feladatot nem lehet feloldani, mert a mozgásban vannak tételek!"
        }
    },
    finish: {
        name: "Továbbítás jóváhagyásra",
        disabled: "A feladatot nem lehet elvégezni, mert egyes elemek nem engedélyezett helyen vannak.",
        done: "#{0} {1} befejezve, átment.",
        doneTotal: "#{0} {1} befejezve",
        status: {
            404: notFoundOrACL,
            409: notActive409,
            423: memberOfSet
        }
    },
    handOver: {
        name: "Átadás visszaigazolása az előfizetőnek",
        done: "#{0} {1} befejezve, átment.",
        status: {
            404: notFoundOrACL,
            409: notActive409,
            423: memberOfSet
        }
    },
    finishAndApprove: "Befejezés",
    finishAndApproveHint: "Ez a művelet az Ön számára elérhető, mert Ön rendelkezik raktáros és menedzser szerepkörrel is.",
    movementsOverview: {
        title: "A feladatban szereplő áthelyezések áttekintése",
        sourceSubstock: "Forrás alraktár",
        destinationSubstock: "Céhely alraktár",
    },

    status: {
        404: "A megadott feladatszám nem található!",
        409: "A feladat még nem teljesült!",
        423: memberOfSet
    }
};
